import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "logo",
  href: "https://loyalty.bms.group/",
  target: "_blank"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.image,
          alt: "Logotype"
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          innerHTML: require(`@/assets/img/icons/logo_bms.svg`)
        }, null, 8, _hoisted_3)),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ]))
}