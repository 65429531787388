import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-draft" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_order_draft_main = _resolveComponent("app-order-draft-main")!
  const _component_app_order_draft_footer = _resolveComponent("app-order-draft-footer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_app_order_draft_main),
    (!_ctx.bonusOnly)
      ? (_openBlock(), _createBlock(_component_app_order_draft_footer, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}