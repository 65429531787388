
import { defineComponent } from 'vue'
import Loyalty from '@/components/home/Loyalty.vue'
import Payment from '@/components/home/payment/Payment.vue'
import Order from '@/components/home/order/Order.vue'
import { mapActions, mapGetters } from 'vuex'
import Preloader from '@/components/UI/Preloader.vue'
import OrderForm from '@/components/home/order/components/form/Form.vue'
import OrderDraft from '@/components/home/payment/components/draft/Draft.vue'

export default defineComponent({
  name: 'Home',
  components: {
    'app-order-draft': OrderDraft,
    'app-loyalty': Loyalty,
    'app-payment': Payment,
    'app-order': Order,
    'app-preloader': Preloader,
    'app-order-form': OrderForm
  },
  props: {
    loyalty: {
      type: Boolean,
      required: true
    },
    isAuthorized: {
      type: Boolean,
      required: true
    },
    isSecretAuthorized: {
      type: Boolean,
      required: true
    },
    isCreated: {
      type: Boolean,
      required: true
    },
    bonusAmount: {
      type: Number,
      required: true
    },
    bonusOnly: {
      type: Boolean,
      required: true
    },
    isPaymentAvailable: {
      type: Boolean,
      required: true
    },
    isPromoCodesExists: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['loadingStatus'])
  },
  watch: {
    async isAuthorized () {
      if (this.isAuthorized) {
        // если авторизовался
        // получаем баланс
        await this.getBalance()
      } else {
        // если разлогинился
        if (this.bonusOnly) {
          // если заказ можно оплатить только бонусами
          // отображаем попап с формой авторизации без возможности его закрыть
          this.toggleModal({
            name: 'auth',
            value: true,
            permanent: true
          })
        }
      }
      // пересчитываем драфт
      // TODO TASK  написать тут проверку на статус заказа перед createDraft
      await this.createDraft()
    }
    // async isSecretAuthorized () {
    //   if (this.isSecretAuthorized) {
    //     // если авторизовался
    //     // получаем баланс
    //     await this.getBalance()
    //     // пересчитываем драфт
    //     // TODO TASK  написать тут проверку на статус заказа перед createDraft
    //     console.log('aaaaa')
    //     await this.createDraft()
    //   } else {
    //     // если разлогинился
    //     await this.getBalance()
    //     if (this.bonusOnly) {
    //       // если заказ можно оплатить только бонусами
    //       // отображаем попап с формой авторизации без возможности его закрыть
    //       this.toggleModal({
    //         name: 'auth',
    //         value: true,
    //         permanent: true
    //       })
    //     }
    //     // получаем баланс
    //     await this.getBalance()
    //     // пересчитываем драфт
    //
    //     console.log('fffff')
    //     await this.createDraft()
    //   }
    // }
  },
  methods: {
    ...mapActions('payment', [
      'processDraft'
    ]),
    ...mapActions('payment', [
      'createDraft'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    ...mapActions('user', [
      'getBalance'
    ]),
    async pay (token: any) {
      await this.processDraft(token)
    }
  }
})
