<template>
<div>
  <app-spinner
    v-if="loading"
    mode="dark"
    size="m"
  />
  <app-auth-form v-else />
</div>
</template>

<script>
import AuthForm from '@/components/auth/RegistrationForm.vue'
import Spinner from '@/components/UI/Spinner.vue'

export default {
  name: 'Login',

  components: {
    'app-auth-form': AuthForm,
    'app-spinner': Spinner
  },

  data: () => {
    return {
      loading: false
    }
  }

}
</script>

<style scoped>

</style>
