<template>
  <div class="crossmarketing">
    <div
      class="crossmarketing__voucher"
      v-if="draftInfo.voucher"
    >
      <div
        class="crossmarketing__gift"
        v-html="require('@/assets/img/gift.svg')"
      ></div>
      <div class="crossmarketing__title crossmarketing__title--red">
        Вам подарок! Персональный ваучер на&nbsp;скидку&nbsp;{{ draftInfo.voucher }}%
      </div>
      <div class="crossmarketing__text">
        Спешите воспользоваться скидкой на&nbsp;покупку акционных товаров и&nbsp;предложений от&nbsp;наших партнеров.
      </div>
      <a href="https://ibonus.bms.group/" target="_blank">
        <app-button>Хочу воспользоваться</app-button>
      </a>
    </div>
    <div
      class="crossmarketing__loyalty"
      v-else
    >
      <div class="crossmarketing__title">Мы&nbsp;начислили бонусы вам на&nbsp;счет!</div>
      <div class="crossmarketing__earn">
        <p>Начислено {{ declOfNum(getEarn()) }}</p>
        <p>{{ getEarn() }}</p>
      </div>
      <div class="crossmarketing__text">
        Потратьте их&nbsp;с&nbsp;пользой. Попробуйте сервис акционных товаров и&nbsp;предложений от&nbsp;наших партнеров.
      </div>
      <a href="https://ibonus.bms.group/" target="_blank">
        <app-button>Хочу потратить бонусы</app-button>
      </a>
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/buttons/Button'
export default {
  name: 'PaymentCrossmarketing',
  components: {
    'app-button': Button
  },
  props: {
    draftInfo: {
      type: Object,
      required: false,
      default: () => ({
        draftId: 0,
        extRequestId: '',
        accountChanges: [
          {
            accountType: 'GLOBAL',
            spend: 0,
            earn: 100
          }
        ],
        leftToPay: 0,
        crossmarketing: {
          discountType: '',
          discount: ''
        }
      })
    }
  },
  computed: {},
  methods: {
    declOfNum (number) {
      const words = ['бонус', 'бонуса', 'бонусов']
      return words[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
      ]
    },
    getEarn () {
      const find = this.draftInfo.accountChanges.find((el) => {
        return el.accountType === 'GLOBAL'
      })
      if (find && find.earn > 0) return find.earn
      return 0
    },
    goToCatalog () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
.crossmarketing {
  background: #ffffff;
  box-shadow: $popup-shadow;
  padding: 15px;
  border-radius: $border-radius;
  overflow: hidden;
  margin: 10px 0 10px;
  &__title {
    color: $title;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    &--red {
      color: $error;
    }
  }
  &__earn {
    border: 1px dashed rgba(126, 125, 142, 0.5);
    border-radius: $border-radius;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: $title;
    font-size: 16px;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 16px;
    color: $text;
    margin-bottom: 15px;
  }
  &__gift {
    position: relative;
    margin-bottom: 10px;
    ::v-deep(svg) {
      position: relative;
      z-index: 1;
    }
    &:after {
      content: "";
      background: #ffb648;
      opacity: 0.6;
      filter: blur(50px);
      position: absolute;
      width: 200px;
      height: 200px;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media(min-width: $desktop-width) {
  .crossmarketing {
    width: 485px;
  }
}

</style>
