import Payment from '@/models/payment.model'
import { ActionContext } from 'vuex'
import PaymentTypes from '@/models/paymentTypes.model'
import SBPBank from '@/models/SBPBank.model'

const getDefaultState = () => {
  return {
    isAppleAvailable: false,
    isGoogleAvailable: true,
    isSBPAvailable: true,
    payServices: [
      'APPLE',
      'GOOGLE',
      'SBP_QR'
    ],
    paymentTypes: [
      'SBP_QR',
      'NEW_CARD',
      'IN_RETAIL_POINT'
      // 'VISA',
      // 'MASTERCARD',
      // 'MAESTRO',
      // 'MIR',
      // 'APPLE',
      // 'GOOGLE'
    ],
    paymentType: 'SBP_QR',
    SBPBank: null
  }
}

const paymentTypes = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_APPLE_AVAILABLE (state: PaymentTypes, data: boolean) {
      state.isAppleAvailable = data
    },
    SET_GOOGLE_AVAILABLE (state: PaymentTypes, data: boolean) {
      state.isGoogleAvailable = data
    },
    SET_PAYMENT_TYPE (state: PaymentTypes, data: string) {
      state.paymentType = data
    },
    SET_SBP_BANK (state: PaymentTypes, data: SBPBank) {
      state.SBPBank = data
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    setAppleAvailable ({ commit }: ActionContext<Payment, any>, data: boolean) {
      commit('SET_APPLE_AVAILABLE', data)
    },
    setGoogleAvailable ({ commit }: ActionContext<Payment, any>, data: boolean) {
      commit('SET_GOOGLE_AVAILABLE', data)
    },
    setPaymentType ({ commit }: ActionContext<Payment, any>, data: string) {
      commit('SET_PAYMENT_TYPE', data)
    }
  },
  getters: {
    isAppleAvailable: (state: PaymentTypes) => state.isAppleAvailable,
    isGoogleAvailable: (state: PaymentTypes) => state.isGoogleAvailable,
    isSBPAvailable: (state: PaymentTypes) => state.isSBPAvailable,
    payServices: (state: PaymentTypes) => state.payServices,
    paymentTypes: (state: PaymentTypes, getters: any, rootGetters: any) => {
      state.payServices.forEach(el => {
        let isAvailable
        switch (el) {
          case 'GOOGLE':
            isAvailable = state.isGoogleAvailable
            break
          case 'APPLE':
            isAvailable = state.isAppleAvailable
            break
          case 'SBP_QR':
            isAvailable = state.isSBPAvailable
            break
        }

        if (isAvailable && state.paymentTypes.indexOf(el) === -1) {
          state.paymentTypes.push(el)
        }
      })

      return rootGetters.loyalty.club.config.paymentTypes.filter((el: string) => state.paymentTypes.includes(el))
    },
    paymentType: (state: PaymentTypes) => state.paymentType,
    SBPBank: (state: PaymentTypes) => state.SBPBank,
    SBPValid: (state: PaymentTypes) => state.paymentType === 'SBP_QR' && state.SBPBank !== null
  }
}

export default paymentTypes
