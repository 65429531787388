
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      valid: true
    }
  },
  methods: {
    updateInput (event: any) {
      this.$emit('update:value', event.target.checked)
    }
  }
})
