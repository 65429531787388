
import { defineComponent } from 'vue'
import OrderFormCheckbox from '@/components/home/order/components/form/components/Checkbox.vue'
import Button from '@/components/UI/buttons/Button.vue'
import ApplePay from '@/components/UI/buttons/pay/Apple.vue'
// import GooglePay from '@/components/UI/buttons/pay/Google.vue'
import SBPPay from '@/components/UI/buttons/pay/SBP.vue'
import { mapGetters } from 'vuex'
import filters from '@/filters'
import PaymentEmail from '@/components/home/payment/components/Email.vue'
// import Note from '@/components/UI/Note.vue'

export default defineComponent({
  name: 'OrderForm',
  components: {
    'app-payment-email': PaymentEmail,
    'app-order-form-checkbox': OrderFormCheckbox,
    'app-button': Button,
    'app-apple-pay': ApplePay,
    //    'app-google-pay': GooglePay,
    'app-sbp-pay': SBPPay
    // 'app-note': Note
  },
  props: {
    bonusOnly: {
      type: Boolean,
      required: true
    },
    isPaymentAvailable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('loyalty', [
      'clubConfig',
      'currencySymbol',
      'emailRequired'
    ]),
    ...mapGetters('paymentTypes', [
      'paymentType',
      'payServices',
      'SBPValid'
    ]),
    ...mapGetters('user', [
      'email',
      'emailValid'
    ]),
    ...mapGetters('payment', [
      'draftExtra'
    ]),
    ...mapGetters('order', [
      'orderTotalValid',
      'orderEditable'
    ]),
    buttonText () {
      if (this.bonusOnly) {
        if (this.isPaymentAvailable) {
          return 'Оплатить бонусами'
        } else {
          return 'Недостаточно бонусов'
        }
      } else {
        if (this.draftExtra.spend > 0) {
          return `Оплатить ${filters.price(this.draftExtra.spend)} ${this.currencySymbol}`
        } else {
          return 'Оплатить бонусами'
        }
      }
    },
    valid () {
      if (this.clubConfig && this.clubConfig.emailRequired) {
        return this.agreed && this.email && this.emailValid && this.orderTotalValid
      } else if (this.orderEditable) {
        return this.agreed && this.orderTotalValid
      } else {
        return this.agreed
      }
    }
  },
  data: () => ({
    agreed: false
  }),
  methods: {
    pay (token: any) {
      this.$emit('pay', token)
    }
  }
})
