import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "loading" }, {
          default: _withCtx(() => [
            (_ctx.showModal)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["modal", `modal--${_ctx.name}`])
                }, [
                  (['payment', 'promocode', 'auth', 'goods', 'banks'].indexOf(_ctx.name) !== -1 && !_ctx.permanent)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "modal__close",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                      }, [
                        _createElementVNode("span", {
                          innerHTML: require(`@/assets/img/icons/close.svg`)
                        }, null, 8, _hoisted_2)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent(`app-${_ctx.name}-modal`), {
                    value: _ctx.showModal,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
                    key: _ctx.name
                  }, null, 8, ["value"]))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.showModal)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "overlay",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ], 64))
}