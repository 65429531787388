import { server } from './index'
import { AxiosResponse } from 'axios'

interface createDraft {
  paymentType: string,
  isSpendBonus: boolean
}

interface processDraft {
  paymentType: string,
  paymentToken: string,
  email: string,
  successUrl: string
}

interface pay {
  clubId: string,
  retailPointId: string,
  orderId: string,
  bonusOnly: boolean,
  goods: [
    {
      id: string,
      code: string,
      image?: string,
      name: string,
      price: string,
      quantity: number,
      sum: number,
    }
  ],
  total: number,
  draftStage: number,
  feature: string,
  editable: boolean
}

const paymentApi = {
  /**
   * Create draft
   * @param data
   * @param data.paymentType string
   * @param data.isSpendBonus boolean
   * @returns {Promise<AxiosResponse<T>>}
   */
  createDraft (data: createDraft): Promise<AxiosResponse> {
    return server.post('payment/createDraft', data)
  },
  /**
   * Process draft
   * @param data
   * @param data.paymentType string
   * @param data.paymentToken string
   * @param data.email string
   * @param data.successUrl string
   * @returns {Promise<AxiosResponse<T>>}
   */
  processDraft (data: processDraft): Promise<AxiosResponse> {
    return server.post('payment/processDraft', data)
  },
  /**
   * Complete draft
   * @returns {Promise<AxiosResponse<T>>}
   */
  completeDraft (): Promise<AxiosResponse> {
    return server.get('payment/completeDraft')
  },
  sendCheque (email: string): Promise<AxiosResponse> {
    return server.post('payment/sendCheque', { email })
  },
  pay (data:pay): Promise<AxiosResponse> {
    return server.post('/payment', data)
  },
  getPayMethods () {
    return server.get('/payment/payMethods')
  }
}

export { paymentApi }
