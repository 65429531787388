
import { defineComponent } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Header from '@/components/header/Header.vue' // @ is an alias to /src
import Error from '@/components/error/Error.vue'
import Modal from '@/components/UI/Modal.vue'
import helpers from '@/helpers/index'
import Footer from '@/components/footer/Footer.vue'
import { furcaApi } from './api/furca'

export default defineComponent({
  name: 'App',
  components: {
    'app-header': Header,
    'app-error': Error,
    'app-modal': Modal,
    'app-footer': Footer
  },
  computed: {
    ...mapGetters([
      'loadingStatus',
      'isExternalPage'
    ]),
    ...mapGetters('auth', [
      'isAuthorized',
      'isSecretAuthorized',
      'login'
    ]),
    ...mapGetters('error', [
      'error',
      'errorModal'
    ]),
    ...mapGetters('user', [
      'balance'
    ]),
    ...mapGetters('loyalty', [
      'retailPointId',
      'clubConfig',
      'clubId'
    ]),
    ...mapGetters('order', [
      'bonusOnly'
    ]),
    ...mapGetters('payment', [
      'isPaymentAvailable'
    ]),
    isIos () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
        (navigator.userAgent.includes('Mac'))
    },
    isSBPQR () {
      return location.pathname === '/sbp-qr'
    }
  },
  watch: {
    isIos: {
      handler (value: boolean) {
        this.setGoogleAvailable(!value)
      },
      immediate: true
    },
    $route () {
      this.checkUrl()
    },
    balance () {
      // this.checkUrl()
    }
  },
  async created () {
    this.setConfig()

    this.setDefaultSBP()
  },
  methods: {
    ...mapActions(['setExternalPage']),
    ...mapActions('config', [
      'setConfig'
    ]),
    ...mapActions('info', [
      'getInfo'
    ]),
    ...mapActions('error', [
      'toggleError'
    ]),
    ...mapActions('payment', [
      'completeDraft',
      'pay',
      'getPayMethods'
    ]),
    ...mapActions('paymentTypes', [
      'setGoogleAvailable'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    ...mapActions('user', [
      'getBalance',
      'setEmail',
      'setEmailValid'
    ]),
    ...mapMutations('paymentTypes', [
      'SET_SBP_BANK'
    ]),
    ...mapMutations('payment', [
      'SET_EDIT_SUM'
    ]),
    setDefaultSBP () {
      const defaultSBP = localStorage.getItem('BMSCheckoutSBP')
      if (defaultSBP) {
        this.SET_SBP_BANK(JSON.parse(defaultSBP))
      }
    },
    parseTokens (query :any = {}) {
      const login = query?.bmsCheckoutUser
      const token = query?.bmsCheckoutToken
      const refresh = query?.bmsCheckoutRefresh
      if (login && token && refresh) {
        localStorage.setItem('bmsCheckoutUser', login)
        localStorage.setItem('bmsCheckoutToken', token)
        localStorage.setItem('bmsCheckoutRefresh', refresh)
      }
    },
    async getUserInfo () {
      await this.getInfo()
      // await this.getPayMethods()
    },
    async checkUrl () {
      const query = helpers.methods.parseUrlQuery()
      const sum = query.s // Сумма платежа
      const retailPointId = query.p // Какая точка
      const terminalCode = query.t // Какой терминал (альт точки)
      const club = query.c // Какой партнер
      const info = query.i // Доп инфа по заказу
      const stage = query.stage // Стадия драфта deprecated
      const feature = query.f // Сценарий для оплаты - e_c значит с внешней корзиной
      const deliveryId = query.d // Id заказа из внешней системы
      const furcaToken = query?.furcaToken
      const id = query?.id
      const email = query.e
      // try {
      //   let furcaData
      //   if (furcaToken) {
      //     const furcaResponse = await furcaApi.getFurcaInfo(furcaToken)
      //     furcaData = furcaResponse?.data?.data
      //     console.log(furcaData)
      //   }
      // } catch (error) {
      //   console.log('get furca info error:', error)
      // }

      if (email) {
        this.setEmail(email)
      }

      this.parseTokens(query)
      if (query.orderId || location.pathname === '/finish') {
        // если вернулись со страницы банка
        // проверяем оплату
        await this.completeDraft()
      } else if (location.pathname === '/registration' && id) {
        await this.getInfo()
        await this.getPayMethods()
      } else if (id) {
        // Если мы перешли не по прямой ссылке на страницу заказа, то заново грузить информацию не нужно
        if (!this.isExternalPage) {
          await this.getUserInfo()
        }
        if (furcaToken) {
          try {
            const furcaResponse = await furcaApi.postFurcaInfo({ token: furcaToken, idLoyaltyProgram: this.clubId, payServiceLogin: this.login })
            const newToken = furcaResponse?.data?.data
            console.log('newToken', newToken)
          } catch (error) {
            console.log('getFurcaError:', error)
          }
        }

        if (this.bonusOnly && !this.isAuthorized) {
          // если заказ можно оплатить только бонусами
          // отображаем попап с формой авторизации без возможности его закрыть
          this.toggleModal({
            name: 'auth',
            value: true,
            permanent: true
          })
        }
      } else if (club && (retailPointId || terminalCode)) {
        this.setExternalPage(true)
        let clubHash = 0
        for (let i = 0; i < club.length; i++) {
          clubHash += club.charCodeAt(i)
        }
        const data = {
          clubId: club,
          retailPointId,
          orderId: Math.floor(new Date().getTime() / 1000) + clubHash + (retailPointId || terminalCode),
          bonusOnly: false,
          furcaToken,
          additionalInfo: info,
          terminalCode,
          deliveryId,
          goods: [
            {
              id: '1',
              code: '1',
              name: 'Получение аванса',
              price: 0,
              quantity: 1,
              sum: 0
            }
          ],
          total: 0,
          draftStage: 0,
          feature: 'normal',
          editable: false
        }
        if (stage) {
          data.draftStage = stage
        }
        if (feature) {
          data.feature = feature
        }
        if (sum) {
          data.goods[0].price = +sum
          data.goods[0].sum = +sum
          data.total = +sum
        }
        if (!sum && !stage && !feature) {
          data.editable = true
        }
        await this.pay({ ...data, furcaToken })
        await this.getBalance()
      } else {
        this.toggleError({
          name: 'not-found',
          value: true
        })
      }
    }
  }
})
