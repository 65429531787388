
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'OrderSubheader',
  computed: {
    ...mapGetters('loyalty', [
      'retailPointTitle',
      'retailPointAddress'
    ])
  }
})
