import Address from '@/models/address.model'

// Функция для "отрезания" числа до x дроби без округления
// Пример: (100.123, 2) -> 100.12
function toFixedHard (number: number, x: number) {
  const s = String(number)
  const [a, b = ''] = s.split('.')
  return parseFloat(a + '.' + b.substring(0, x))
}

const filters = {
  price (v: number): string {
    if (!v.toString().length) return ''
    return toFixedHard(v, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  },
  address (v: Address): string {
    if (!v) return ''

    const {
      street,
      house,
      flat
    } = v
    return `${street || ''} ${house || ''} ${flat ? '/' + flat : ''}`
  },
  login (v: string): string {
    if (!v) return ''

    // v в формате login_clubId
    // берем значение login из v
    v = v.replace('WEB', '').split('_').shift() || ''

    console.log(v)

    return `+${v[0]} (${v.slice(1, 4)}) ${v.slice(4, 7)}-${v.slice(7, 9)}-${v.slice(9, 11)}`
  }
}
export default filters
