import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", null, [
    (_ctx.draft.convertedPay)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$filters.price(_ctx.draft.convertedPay.value)) + " ₽", 1)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$filters.price(_ctx.sum)) + " " + _toDisplayString(_ctx.currencySymbol), 1)
        ], 64))
  ]))
}