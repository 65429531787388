import Error from '@/models/error.model'

const getDefaultState = () => {
  return {
    value: false,
    name: 'internal'
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_ERROR (state: Error, data: Error): void {
      state.value = data.value
      state.name = data.name
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    toggleError ({ commit, dispatch, getters }: any, data: Error): void {
      commit('SET_ERROR', data)

      const showModal = getters.errorModal ? data.value : false
      dispatch('modal/toggleModal', { name: 'error', value: showModal }, { root: true })
    }
  },
  getters: {
    value: (state: Error): boolean => state.value,
    name: (state: Error): string => state.name,
    error: (state: Error): boolean => {
      return state.value && ['internal', 'not-found', 'wrong-config'].indexOf(state.name) !== -1
    },
    errorModal: (state: Error): boolean => {
      return state.value && ['draft', 'payment-type', 'already-payed'].indexOf(state.name) !== -1
    }
  }
}
