
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'
import Button from '@/components/UI/buttons/Button.vue'
import Spinner from '@/components/UI/Spinner.vue'
import Balance from '@/components/home/Balance.vue'
import PaymentCrossmarketing from '@/components/home/payment/PaymentCrossmarketing.vue'
import { AccountChange } from '@/models/draft.model'
import router from '@/router'
// import helpers from '@/helpers/index'
// import { furcaApi } from '../api/furca'

export default defineComponent({
  name: 'Finish',
  components: {
    'app-input': Input,
    'app-button': Button,
    'app-spinner': Spinner,
    'app-loyalty': Balance,
    'app-crossmarketing': PaymentCrossmarketing
  },
  props: {
    loyalty: {
      type: Boolean,
      required: true
    },
    isAuthorized: {
      type: Boolean,
      required: true
    },
    isCreated: {
      type: Boolean,
      required: true
    },
    bonusAmount: {
      type: Number,
      required: true
    },
    bonusOnly: {
      type: Boolean,
      required: true
    },
    isPaymentAvailable: {
      type: Boolean,
      required: true
    }
  },
  async created () {
    if (!this.completeResponse.completed) {
      this.checkWindowVisability()
      this.SET_LOADING_STATUS('pending')
    }
    document.addEventListener('visibilitychange', this.checkWindowVisability)
  },
  watch: {
    '$route' (route) {
      if (route.name !== 'Finish') {
        this.clearInterval()
        document.removeEventListener('visibilitychange', this.checkWindowVisability)
      }
    },
    completeResponse (draft) {
      if (draft.completed) {
        this.clearInterval()
        // this.isMessageSended = true
        // const query = helpers.methods.parseUrlQuery()
        // const furcaToken = query?.furcaToken
        // furcaApi.bulkNotification({ messages: [{ message: `Вы оплатили заказ ${this.completeResponse.draftId}.${this.completeResponse.checkUrl ? ` Ваш чек доступен по ссылке ${this.completeResponse.chequeUrl}` : ''}` }], token: furcaToken, login: this.login })
      }
    }
  },
  data: () => ({
    email: '',
    valid: false,
    emailRequestStatus: '',
    completeRequestInterval: 0
    // isMessageSended: false
  }),
  methods: {
    ...mapActions('payment', [
      'sendCheque',
      'completeDraft'
    ]),
    ...mapMutations(['SET_LOADING_STATUS']),
    goToRegistration () {
      router.push(`/registration?id=${router.currentRoute.value.query.id}`)
    },
    async send () {
      if (this.valid) {
        try {
          this.emailRequestStatus = 'pending'
          await this.sendCheque(this.email)
          this.emailRequestStatus = 'ok'
        } catch (e) {
          this.emailRequestStatus = 'error'
        }
      }
    },
    checkWindowVisability () {
      if (document.hidden) {
        this.clearInterval()
      } else {
        if (!this.completeResponse.completed) {
          this.completeRequestInterval = window.setInterval(() => {
            this.completeDraft()
          }, 5000)
        } else {
          this.clearInterval()
        }
      }
    },
    clearInterval () {
      window.clearInterval(this.completeRequestInterval)
    }
  },
  computed: {
    ...mapGetters('payment', [
      'completeResponse'
    ]),
    ...mapGetters('auth', ['isAuthorized']),
    ...mapGetters([
      'loadingStatus'
    ]),
    ...mapGetters('auth', [
      'login'
    ]),
    bonusEarn () {
      console.log(this.completeResponse)
      const global = this.completeResponse.accountChanges.find((item: AccountChange) => item.accountType === 'GLOBAL')
      return (global && global.earn) || 0
    },
    bonusSpend () {
      const global = this.completeResponse.accountChanges.find((item: AccountChange) => item.accountType === 'GLOBAL')
      return (global && global.spend) || 0
    },
    extraSpend () {
      const extra = this.completeResponse.accountChanges.find((item: AccountChange) => item.accountType === 'EXTRA')
      return (extra && extra.spend) || 0
    },
    totalAmount () {
      const total = this.completeResponse.total
      return total || 0
    }
  }
})
