
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import IMaskDirective from '@/directives/mask'

export default defineComponent({
  name: 'Input',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'text',
      validator (value: string) {
        return ['text', 'phone', 'code', 'emailCode', 'email', 'promocode', 'sum'].indexOf(value) !== -1
      }
    },
    value: {
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    wrongCode: {
      type: Boolean,
      required: false,
      default: false
    },
    wrongPromocode: {
      type: Boolean,
      required: false,
      default: false
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    currencySymbol: {
      type: String,
      required: false,
      default: '₽'
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    id: uuidv4(),
    mask: {
      mask: '',
      lazy: true,
      placeholderChar: '_'
    },
    max: 1000,
    valid: true,
    message: ''
  }),
  computed: {
    inputMode () {
      if (this.mode === 'email') {
        return 'email'
      } else if (this.mode === 'phone') {
        return 'tel'
      } else if (this.mode === 'sum') {
        return 'number'
      } else {
        return 'text'
      }
    },
    inputAutocomplete () {
      if (this.mode === 'email') {
        return 'email'
      } else if (this.mode === 'phone') {
        return 'tel'
      } else {
        return 'off'
      }
    }
  },
  watch: {
    focus: {
      handler () {
        if (this.focus && this.$refs.input) {
          (this.$refs.input as InstanceType<typeof HTMLInputElement>).focus()
        }
      }
    },
    wrongCode () {
      this.validate(this.value)
    },
    wrongPromocode () {
      this.validate(this.value)
    },
    valid () {
      this.$emit('update:valid', this.valid)
    }
  },
  mounted () {
    this.setInputBehavior()
    this.$nextTick(function () {
      if (this.focus) {
        (this.$refs.input as InstanceType<typeof HTMLInputElement>).focus()
      }
      if (this.mode !== 'phone') {
        (this.$refs.input as InstanceType<typeof HTMLInputElement>).value = this.value;
        (this.$refs.input as InstanceType<typeof HTMLInputElement>).dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  },
  methods: {
    setInputBehavior () {
      this.mask.mask = '*'.repeat(this.max)

      switch (this.mode) {
        case 'phone':
          this.mask = {
            mask: '+{7} (000) 000-00-00',
            lazy: true,
            placeholderChar: '_'
          }
          break
        case 'code':
          this.mask = {
            mask: '0 0 0 0 0',
            lazy: true,
            placeholderChar: '•'
          }
          break
        case 'emailCode':
          this.mask = {
            mask: '0 0 0 0',
            lazy: true,
            placeholderChar: '•'
          }
          break
        case 'email':
          this.mask = {
            mask: '*'.repeat(this.max),
            lazy: true,
            placeholderChar: '_'
          }
          break
        default:
          this.mask = {
            mask: '*'.repeat(this.max),
            lazy: true,
            placeholderChar: '_'
          }
          break
      }
    },
    validate (value: string) {
      this.valid = true
      if (this.required && !value) {
        this.message = 'Поле обязательно для заполнения'
        this.valid = false
        return
      }

      switch (this.mode) {
        case 'phone':
          if (value.length < 18) {
            this.message = 'Введены не все символы'
            this.valid = false
          }
          break
        case 'code':
          if (this.wrongCode) {
            this.message = 'Неверный код из SMS. Повторите ввод'
            this.valid = false
          } else if (value.length < 9) {
            this.message = 'Введены не все символы'
            this.valid = false
          }
          break
        case 'emailCode':
          if (this.wrongCode) {
            this.message = 'Неверный код. Повторите ввод'
            this.valid = false
          } else if (value.length < 7) {
            this.message = 'Введены не все символы'
            this.valid = false
          }
          break
        case 'email':
          if (!/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(value)) {
            this.message = 'Неверный формат электронной почты'
            this.valid = false
          }
          break
        case 'promocode':
          if (this.wrongPromocode) {
            this.message = 'Несуществующий промокод'
            this.valid = false
          }
          break
        case 'sum':
          if (+value < 10) {
            this.message = `Минимальная сумма 10 ${this.currencySymbol}`
            this.valid = false
          }
          break
      }
    },
    clear () {
      // console.log('clear')
      this.$emit('update:value', '')
      this.$emit('clear');
      (this.$refs.input as InstanceType<typeof HTMLInputElement>).focus()
    },
    onAccept (e: any) {
      // console.log('onAccept')
      this.$emit('update:value', e.detail.unmaskedValue)
      this.validate(e.detail.value)
      this.$emit('update:valid', this.valid)
    },
    onComplete () {
      // console.log('onComplete')
    }
  },
  directives: {
    imask: IMaskDirective
  }
})
