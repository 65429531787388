import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_overlay = _resolveComponent("app-overlay")!
  const _component_app_spinner = _resolveComponent("app-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_overlay, { value: _ctx.value }, null, 8, ["value"]),
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_app_spinner, {
          key: 0,
          mode: "dark",
          size: "l",
          fixed: ""
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}