import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled", "readonly"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`button-link button-link--size--${_ctx.size} button-link--color--${_ctx.color}`),
    disabled: _ctx.disabled,
    readonly: _ctx.readonly
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "button-link__icon",
          innerHTML: require(`@/assets/img/icons/${_ctx.icon}.svg`)
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.arrow)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "button-link__arrow",
          innerHTML: require(`@/assets/img/icons/arrow.svg`)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}