import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!

  return (_openBlock(), _createBlock(_component_app_input, {
    value: _ctx.sumValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sumValue) = $event)),
    valid: _ctx.sumValueValid,
    "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sumValueValid) = $event)),
    mode: "sum",
    label: "Введите сумму",
    required: true,
    onMouseleave: _ctx.onBlur,
    "currency-symbol": _ctx.currencySymbol
  }, null, 8, ["value", "valid", "onMouseleave", "currency-symbol"]))
}