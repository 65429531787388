
import { defineComponent } from 'vue'
import ButtonLink from '@/components/UI/buttons/Link.vue'

export default defineComponent({
  name: 'Timer',
  components: {
    'app-button-link': ButtonLink
  },
  props: {
    resended: { // true, если запрос на отправку токена отправлен успешно
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    timer: 0,
    time: 59,
    sended: true // true, если запрос на отправку токена отправлен и таймер еще не истек
  }),
  watch: {
    time () {
      if (this.time === 0) {
        this.stopTimer()
        this.sended = false
        this.time = 59
      }
    },
    resended: {
      handler () {
        if (this.resended) {
          this.startTimer()
        }
      },
      immediate: true
    }
  },
  methods: {
    startTimer () {
      this.timer = window.setInterval(() => {
        this.time--
      }, 1000)
    },
    stopTimer () {
      clearInterval(this.timer)
    },
    resend () {
      this.$emit('send')
      this.sended = true
    }
  }
})
