
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Radio from '@/components/UI/inputs/Radio.vue'
import type PaymentMethod from '@/models/paymentMethod.model'

export default defineComponent({
  name: 'PaymentRadioGroup',
  components: {
    'app-radio': Radio
  },
  computed: {
    ...mapGetters('paymentTypes', [
      'paymentTypes',
      'paymentType'
    ]),
    ...mapGetters('user', [
      'cardNum'
    ]),
    ...mapGetters('payment', [
      'paymentMethods'
    ]),
    ...mapGetters('loyalty', [
      'currency'
    ]),
    radioValue: {
      get () {
        return this.paymentType
      },
      set (value: string): void {
        this.toggleModal({
          name: '',
          value: false
        })

        this.setPaymentType(value)
      }
    }
  },
  data: () => ({}),
  watch: {
    async paymentType () {
      await this.createDraft()
    }
  },
  methods: {
    ...mapActions('payment', [
      'createDraft'
    ]),
    ...mapActions('paymentTypes', [
      'setPaymentType'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ])
  }
})
