<template>
  <div
    lang="ru"
    id="apple-pay-button"
    :class="{ 'disabled': disabled }"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { appleApi } from '@/api/apple'

/* eslint-disable no-undef */
export default {
  name: 'ApplePay',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    finished: false
  }),
  computed: {
    ...mapGetters('config', [
      'getAppleMerchantId',
      'getAppleMerchantName'
    ]),
    ...mapGetters('payment', [
      'draftExtra',
      'completeResponse'
    ]),
    ...mapGetters([
      'loadingStatus'
    ])
  },
  mounted () {
    const button = document.getElementById('apple-pay-button')

    if (window.ApplePaySession && ApplePaySession.canMakePaymentsWithActiveCard(this.getAppleMerchantId)) {
      this.setAppleAvailable(true)
    }

    // handle the apple pay button click
    button.addEventListener('click', () => {
      const request = {
        countryCode: 'RU',
        currencyCode: 'RUB',
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'],
        total: {
          label: this.getAppleMerchantName,
          amount: `${this.draftExtra.spend}`
        },
        requiredShippingContactFields: [
          'email'
        ]
      }

      const applePayVersion = 3

      const applePaySession = new ApplePaySession(applePayVersion, request)

      applePaySession.begin()

      // This is the first event that Apple triggers. Here you need to validate the
      // Apple Pay Session from your Back-End
      applePaySession.onvalidatemerchant = (e) => {
        const theValidationUrl = e.validationURL
        this.validateTheSession(theValidationUrl, (merchantSession) => {
          applePaySession.completeMerchantValidation(merchantSession)
        })
      }

      // This triggers after the user has confirmed the transaction with the Touch ID or Face ID
      // This will contain the payment token
      applePaySession.onpaymentauthorized = (e) => {
        const emailAddress = e.payment.shippingContact.emailAddress
        const token = e.payment.token
        const paymentData = token.paymentData

        this.pay(paymentData, (outcome) => {
          if (outcome.message && outcome.message === 'Payment completed and result saved') {
            applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS)
          } else {
            applePaySession.completePayment(ApplePaySession.STATUS_FAILURE)
          }
        })
      }
    })
  },
  methods: {
    ...mapActions('paymentTypes', [
      'setAppleAvailable'
    ]),
    async validateTheSession (theValidationUrl, callback) {
      // We send the validation url to our backend
      const res = await appleApi.validate(theValidationUrl)
      callback(res.data.data)
    },
    async pay (token, callback) {
      this.$emit('pay', JSON.stringify(token))

      const unwatch = this.$watch('loadingStatus', () => {
        if (this.loadingStatus !== 'pending') {
          this.finished = true
          callback(this.completeResponse)
        }
      }, { immediate: true })

      if (this.finished) unwatch()
    }
  }
}
</script>

<style lang="scss">
#apple-pay-button {
  width: 100%;
  height: 50px;
  max-height: 50px;
  -webkit-appearance: -apple-pay-button;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
