import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "radio-item-subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_radio = _resolveComponent("app-radio")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTypes, (paymentType) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "radio__item",
        key: paymentType
      }, [
        _createVNode(_component_app_radio, {
          mode: paymentType,
          "card-num": _ctx.cardNum,
          value: _ctx.radioValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.radioValue) = $event)),
          name: "group",
          currency: _ctx.currency
        }, null, 8, ["mode", "card-num", "value", "currency"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.paymentMethods.filter(( item ) => item.name === paymentType).length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" Комиссия банка: " + _toDisplayString(_ctx.paymentMethods.filter(( item ) => item.name === paymentType)[0].servicePercent) + "% ", 1)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}