
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'
import Checkbox from '@/components/UI/inputs/Checkbox.vue'
import Button from '@/components/UI/buttons/Button.vue'
import Link from '@/components/UI/links/Link.vue'
import Spinner from '@/components/UI/Spinner.vue'
import Timer from '@/components/auth/components/Timer.vue'

// Index Signature
interface errors {
  [field: string]: boolean;
}

export default defineComponent({
  name: 'Auth',
  components: {
    'app-input': Input,
    'app-checkbox': Checkbox,
    'app-button': Button,
    'app-link': Link,
    'app-spinner': Spinner,
    'app-timer': Timer
  },
  data: () => ({
    showPhone: true, // показывать форму для телефона либо для почты
    form: {
      phone: '',
      email: '',
      code: '',
      agreed: false
    },
    phoneValid: false,
    emailValid: false,
    codeValid: false,
    sended: false, // true, если первый запрос на отправку токена отправлен успешно
    resended: false, // true, если повторный запрос на отправку токена отправлен успешно
    loader: false,
    errors: {
      server: false,
      code: false
    } as errors, // it`s needs index signature for loop through the object
    activeTab: 'phone'
  }),
  computed: {
    ...mapGetters('loyalty', [
      'clubTitle',
      'clubConfig'
    ]),
    ...mapGetters('order', [
      'bonusOnly'
    ]),
    personalDataPolicy () {
      return this.clubConfig.policy.personalDataPolicy
    },
    personalDataOferta () {
      return this.clubConfig.policy.personalDataOferta
    },
    formValid () {
      return (this.form.phone && this.phoneValid && this.form.agreed) ||
        (this.form.email && this.emailValid && this.form.agreed)
    },
    authMethods () {
      return this.clubConfig?.authMethods || []
    }
  },
  mounted () {
    console.log('sss', this.authMethods)
    if (this.authMethods.length === 0 || this.authMethods.includes('phone')) {
      this.activeTab = 'phone'
    } else {
      this.activeTab = 'email'
    }
  },
  watch: {
    async 'form.code' () {
      if (this.codeValid) {
        try {
          this.loader = true
          const approvedData = await this.approveToken({
            phone: this.form.phone,
            email: this.form.email,
            code: this.form.code
          })
          if (approvedData && approvedData.data.login !== null) {
            await this.getInfo()
          }

          this.toggleModal({
            name: 'auth',
            value: false
          })
        } catch (e: any) {
          console.log('auth modal error', e)
          if (e && e.data && e.data.error && e.data.error.code === 2101) {
            this.errors.code = true
          } else {
            this.errors.server = true
          }
        } finally {
          this.loader = false
        }
      }
    },
    form: {
      handler () {
        this.clearErrors()
      },
      deep: true
    },

    showPhone () {
      this.form = {
        phone: '',
        email: '',
        code: '',
        agreed: false
      }
      this.sended = false
    }
  },
  methods: {
    ...mapActions('auth', [
      'sendToken',
      'approveToken',
      'sendEmailToken'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    ...mapActions('info', [
      'getInfo'
    ]),
    async send (): Promise<any> {
      this.resended = false
      this.clearErrors()
      try {
        this.loader = true
        this.form.phone
          ? await this.sendToken(this.form.phone)
          : await this.sendEmailToken(this.form.email)
        this.sended = true
        this.resended = true
      } catch (e) {
        this.errors.server = true
      } finally {
        this.loader = false
      }
    },
    clearErrors () {
      for (const error in this.errors) {
        this.errors[error] = false
      }
    }
  }
})
