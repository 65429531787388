
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'
import Button from '@/components/UI/buttons/Button.vue'

export default defineComponent({
  name: 'PromocodeModal',
  components: {
    'app-input': Input,
    'app-button': Button
  },
  computed: {
    valid () {
      return this.promocode && this.promocodeValid
    }
  },
  data: () => ({
    promocode: '',
    promocodeValid: true,
    loader: false,
    error: false
  }),
  watch: {
    promocode () {
      this.clearErrors()
    }
  },
  methods: {
    ...mapActions('modal', [
      'toggleModal'
    ]),
    ...mapActions('payment', [
      'createDraft'
    ]),
    clearErrors () {
      this.error = false
    },
    async send (): Promise<void> {
      this.clearErrors()
      try {
        this.loader = true
        await this.createDraft(this.promocode)

        this.toggleModal({
          name: 'promocode',
          value: false
        })
      } catch (e) {
        this.error = true
      } finally {
        this.loader = false
      }
    }
  }
})
