
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Button from '@/components/UI/buttons/Button.vue'

export default defineComponent({
  name: 'GoodsModal',
  components: {
    'app-button': Button
  },
  computed: {
    ...mapGetters('order', [
      'orderGoods'
    ]),
    ...mapGetters('loyalty', [
      'currencySymbol'
    ])
  },
  methods: {
    ...mapActions('modal', [
      'toggleModal'
    ])
  }
})
