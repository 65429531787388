
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Checkbox from '@/components/UI/inputs/Checkbox.vue'
import Link from '@/components/UI/links/Link.vue'

export default defineComponent({
  name: 'OrderFormCheckbox',
  components: {
    'app-checkbox': Checkbox,
    'app-link': Link
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('loyalty', [
      'clubConfig'
    ]),
    termsOfPurchase () {
      return this.clubConfig.policy.termsOfPurchase
    },
    personalDataUsage () {
      return this.clubConfig.policy.personalDataUsage
    },
    personalDataPolicy () { // is required, always returns a value
      return this.clubConfig.policy.personalDataPolicy
    },
    personalDataOferta () { // is required, always returns a value
      return this.clubConfig.policy.personalDataOferta
    },
    agreed: {
      get () {
        return this.value
      },
      set (v: boolean) {
        this.$emit('update:value', v)
      }
    }
  }
})
