import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1173f7b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-form" }
const _hoisted_2 = {
  key: 1,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_order_form_checkbox = _resolveComponent("app-order-form-checkbox")!
  const _component_app_payment_email = _resolveComponent("app-payment-email")!
  const _component_app_apple_pay = _resolveComponent("app-apple-pay")!
  const _component_app_google_pay = _resolveComponent("app-google-pay")!
  const _component_app_sbp_pay = _resolveComponent("app-sbp-pay")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_app_order_form_checkbox, {
      value: _ctx.agreed,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.agreed) = $event)),
      class: "checkbox"
    }, null, 8, ["value"]),
    (_ctx.emailRequired)
      ? (_openBlock(), _createBlock(_component_app_payment_email, {
          key: 0,
          class: "payment__email"
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_app_apple_pay, {
      disabled: !_ctx.valid,
      onPay: _ctx.pay
    }, null, 8, ["disabled", "onPay"]), [
      [_vShow, _ctx.paymentType === 'APPLE']
    ]),
    _withDirectives(_createVNode(_component_app_google_pay, {
      disabled: !_ctx.valid,
      onPay: _ctx.pay
    }, null, 8, ["disabled", "onPay"]), [
      [_vShow, _ctx.paymentType === 'GOOGLE']
    ]),
    _withDirectives(_createVNode(_component_app_sbp_pay, {
      class: "form-btn",
      disabled: !_ctx.valid || !_ctx.SBPValid,
      onPay: _ctx.pay
    }, null, 8, ["disabled", "onPay"]), [
      [_vShow, _ctx.paymentType === 'SBP_QR' && _ctx.draftExtra.spend > 0]
    ]),
    _withDirectives(_createVNode(_component_app_button, {
      class: "form-btn",
      disabled: !_ctx.valid || !_ctx.isPaymentAvailable,
      onClick: _ctx.pay
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]), [
      [_vShow, _ctx.payServices.indexOf(_ctx.paymentType) === -1 || _ctx.draftExtra.spend == 0]
    ]),
    (!_ctx.isPaymentAvailable)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Покупка невозможна. У вас недостаточно бонусов для оплаты товара. "))
      : _createCommentVNode("", true)
  ]))
}