
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Radio',
  props: {
    mode: {
      type: String,
      required: true,
      validator (value: string) {
        return [
          'NEW_CARD',
          'VISA',
          'MASTERCARD',
          'MAESTRO',
          'MIR',
          'APPLE',
          'GOOGLE',
          'SPB_QR'].indexOf(value) !== -1
      }
    },
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    cardNum: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    currency: {
      type: String,
      required: false
    }
  },
  computed: {
    cardType () {
      switch (this.mode) {
        case 'VISA':
          return 'Visa'
        case 'MASTERCARD':
          return 'Mastercard'
        case 'MAESTRO':
          return 'Maestro'
        case 'MIR':
          return 'Mir'
        case 'APPLE':
          return 'Apple Pay'
        case 'GOOGLE':
          return 'Google Pay'
        case 'SBP_QR':
          return 'СБП'
        case 'NEW_CARD':
          return 'Оплата картой'
        default:
          return ''
      }
    },
    cardIcon () {
      switch (this.mode) {
        case 'VISA':
          return 'visa'
        case 'MASTERCARD':
          return 'mastercard'
        case 'MAESTRO':
          return 'maestro'
        case 'MIR':
          return 'mir'
        case 'APPLE':
          return 'apple'
        case 'GOOGLE':
          return 'google'
        case 'SBP_QR':
          return 'SBP'
        case 'NEW_CARD':
        default:
          return ''
      }
    }
  },
  methods: {
    updateInput () {
      this.$emit('update:value', this.mode)
    }
  }
})
