import { createStore } from 'vuex'
import auth from './modules/auth'
import modal from './modules/modal'
import payment from './modules/payment'
import error from './modules/error'
import user from './modules/user'
import loyalty from './modules/loyalty'
import order from './modules/order'
import info from './modules/info'
import config from './modules/config'
import paymentTypes from './modules/paymentTypes'

const getDefaultState = () => {
  return {
    loadingStatus: 'ok', // ok, error, pending
    activeLoadersCount: 0,
    externalPage: false
  }
}

export default createStore({
  state: getDefaultState(),
  mutations: {
    'SET_LOADING_STATUS' (state: any, data: string) {
      if (data === 'pending') {
        state.activeLoadersCount++
      } else {
        state.activeLoadersCount--
      }
      if (state.activeLoadersCount !== 0) {
        state.loadingStatus = 'pending'
      } else {
        state.loadingStatus = data
      }
    },
    'SET_EXTERNAL_PAGE' (state: any, value: boolean) {
      state.externalPage = value
    }
  },
  actions: {
    resetState ({ commit }: any) {
      commit('auth/RESET_STATE', null, { root: true })
      commit('modal/RESET_STATE', null, { root: true })
      commit('payment/RESET_STATE', null, { root: true })
      commit('error/RESET_STATE', null, { root: true })
      commit('user/RESET_STATE', null, { root: true })
      // commit('loyalty/RESET_STATE', null, { root: true })
      // commit('order/RESET_STATE', null, { root: true })
      commit('info/RESET_STATE', null, { root: true })
      commit('config/RESET_STATE', null, { root: true })
      commit('paymentTypes/RESET_STATE', null, { root: true })
    },
    setExternalPage ({ commit }: any, value: boolean) {
      commit('SET_EXTERNAL_PAGE', value)
    }
  },
  getters: {
    loadingStatus: (state: any): string => state.loadingStatus,
    isExternalPage: (state: any): boolean => state.externalPage
  },
  modules: {
    auth,
    modal,
    payment,
    error,
    user,
    loyalty,
    order,
    info,
    config,
    paymentTypes
  }
})
