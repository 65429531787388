import { server } from './index'
import { AxiosResponse } from 'axios'

const infoApi = {
  /**
   * Get info about user, loyalty and order
   * @returns {Promise<AxiosResponse<T>>}
   */
  getInfo (): Promise<AxiosResponse> {
    return server.get('info')
  }
}

export { infoApi }
