
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Link',
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    }
  }
})
