<template>
  <div class="qr-code">
    <qr-code-vue
      ref="qrcode"
      :text="text"
    />
  </div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'Qr',
  components: {
    'qr-code-vue': VueQRCodeComponent
  },
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
