
import { defineComponent } from 'vue'
import Link from '@/components/UI/links/Link.vue'

export default defineComponent({
  name: 'Footer',
  components: {
    'app-link': Link
  },
  data: () => ({
    link: 'https://loyalty.bms.group/'
  })
})
