
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import PaymentModal from '@/components/home/payment/PaymentModal.vue'
import PromocodeModal from '@/components/home/order/components/PromocodeModal.vue'
import AuthModal from '@/components/auth/AuthModal.vue'
import ErrorModal from '@/components/error/Error.vue'
import Overlay from '@/components/UI/Overlay.vue'
import SBPQR from '@/views/SBP-QR.vue'
import GoodsModal from '@/components/home/order/components/GoodsModal.vue'
import BankListModal from '@/components/home/payment/components/BankListModal.vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bodyScrollLock = require('body-scroll-lock')
const disableBodyScroll = bodyScrollLock.disableBodyScroll
const enableBodyScroll = bodyScrollLock.enableBodyScroll

export default defineComponent({
  name: 'Modal',
  components: {
    'app-payment-modal': PaymentModal,
    'app-promocode-modal': PromocodeModal,
    'app-auth-modal': AuthModal,
    'app-error-modal': ErrorModal,
    'app-goods-modal': GoodsModal,
    'app-overlay': Overlay,
    'app-sbp-modal': SBPQR,
    'app-banks-modal': BankListModal
  },
  props: {
    closeIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('modal', [
      'value',
      'name',
      'permanent'
    ]),
    showModal: {
      get (): boolean {
        return this.value
      },
      set (value: boolean): void {
        this.toggleModal({
          name: this.name,
          value
        })
      }
    }
  },
  watch: {
    value () {
      const body = document.querySelector('body')
      if (this.value) {
        disableBodyScroll(body)
      } else {
        enableBodyScroll(body)
      }
    }
  },
  methods: {
    ...mapActions('modal', [
      'toggleModal'
    ]),
    close (): void {
      if (!this.permanent) {
        this.showModal = false
      }
    }
  }
})
