import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "button",
      class: "tooltip__icon",
      "aria-describedby": "tooltip",
      innerHTML: require(`@/assets/img/icons/${_ctx.icon}.svg`),
      style: _normalizeStyle(`width: ${_ctx.size[0]}px; height: ${_ctx.size[1]}px;`),
      tabindex: 0,
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.show && _ctx.show(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.show && _ctx.show(...args))),
      onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)))
    }, null, 44, _hoisted_2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          ref: "tooltip",
          class: "tooltip__message",
          role: "tooltip"
        }, _toDisplayString(_ctx.message), 513), [
          [_vShow, _ctx.visible]
        ])
      ]),
      _: 1
    })
  ]))
}