
import { defineComponent } from 'vue'
import qr from '@/components/UI/QR.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'SBP_QR',
  components: {
    'app-qr': qr
  },
  computed: {
    ...mapGetters('payment', [
      'draftExtra',
      'getSbpQrURL',
      'draft'
    ]),
    sum ():string {
      if (this.draft.convertedPay) {
        return this.draft.convertedPay.value
      } else {
        return this.draftExtra ? '' + this.draftExtra.spend : '0'
      }
    }
  }
})
