import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a093c94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_app_login = _resolveComponent("app-login")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_app_logo, {
      image: _ctx.clubConfig.logo,
      title: null
    }, null, 8, ["image"]),
    (_ctx.loyalty)
      ? (_openBlock(), _createBlock(_component_app_login, {
          key: 0,
          "is-authorized": _ctx.isAuthorized,
          "is-created": _ctx.isCreated,
          onClick: _ctx.login
        }, null, 8, ["is-authorized", "is-created", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}