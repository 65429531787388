
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { defineComponent } from 'vue'
// import PaymentRadio from '@/components/home/payment/components/Radio.vue'
// import PaymentRadioGroup from '@/components/home/payment/components/RadioGroup.vue'
import PaymentEmail from '@/components/home/payment/components/Email.vue'
// import SumInput from '@/components/home/payment/components/Sum.vue'
// import PaymentBankList from '@/components/home/payment/components/BankButton.vue'
import { mapGetters, mapActions } from 'vuex'
// import PaymentMethod from '@/models/paymentMethod.model'
import Button from '@/components/UI/buttons/Button.vue'

export default defineComponent({
  name: 'Payment',
  components: {
    // 'app-payment-radio': PaymentRadio,
    // 'app-payment-radio-group': PaymentRadioGroup,
    // 'app-payment-email': PaymentEmail,
    // 'app-payment-bank-list': PaymentBankList,
    // 'app-payment-sum': SumInput,
    'app-button': Button,
    // 'app-order-draft': OrderDraft,
    Splide,
    SplideSlide
  },
  props: {
    bonusOnly: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('paymentTypes', [
      'paymentTypes',
      'paymentType',
      'SBPBank'
    ]),
    ...mapGetters('order', [
      'orderEditable'
    ]),
    ...mapGetters('payment', [
      'draft',
      'paymentMethods'
    ]),
    ...mapGetters('loyalty', [
      'emailRequired'
    ])
  },
  data: () => ({
    visiblePaymentTypes: 2,
    config: {
      itemsToShow: 2.5,
      gap: 5,
      wrapAround: true
    }
  }),
  methods: {
    ...mapActions('payment', [
      'getPayMethods',
      'createDraft'
    ]),
    ...mapActions('paymentTypes', [
      'setPaymentType'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    showBankList () {
      this.toggleModal({
        name: 'banks',
        value: true
      })
    }
  },
  watch: {
    async paymentType () {
      await this.createDraft()
    }
  },
  async mounted () {
    await this.getPayMethods()
  }
})
