
import { defineComponent } from 'vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bodyScrollLock = require('body-scroll-lock')
const disableBodyScroll = bodyScrollLock.disableBodyScroll
const enableBodyScroll = bodyScrollLock.enableBodyScroll

export default defineComponent({
  name: 'Overlay',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    value: {
      handler () {
        const body = document.querySelector('body')
        if (this.value) {
          disableBodyScroll(body)
        } else {
          enableBodyScroll(body)
        }
      },
      immediate: true
    }
  }
})
