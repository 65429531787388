import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_error = _resolveComponent("app-error")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isSBPQR)
      ? (_openBlock(), _createBlock(_component_app_header, {
          key: 0,
          loyalty: !_ctx.clubConfig.onlyPayment || _ctx.clubConfig.needAuth,
          "is-authorized": _ctx.isAuthorized,
          "is-created": !!_ctx.balance
        }, null, 8, ["loyalty", "is-authorized", "is-created"]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_app_error, { key: 1 }))
      : (_openBlock(), _createBlock(_component_router_view, {
          key: 2,
          loyalty: !!_ctx.retailPointId && !_ctx.clubConfig.onlyPayment,
          "is-authorized": _ctx.isAuthorized,
          "is-secret-authorized": _ctx.isSecretAuthorized,
          "is-created": !!_ctx.balance,
          "bonus-amount": _ctx.balance,
          "bonus-only": _ctx.bonusOnly,
          "is-payment-available": _ctx.isPaymentAvailable
        }, null, 8, ["loyalty", "is-authorized", "is-secret-authorized", "is-created", "bonus-amount", "bonus-only", "is-payment-available"])),
    (!_ctx.isSBPQR)
      ? (_openBlock(), _createBlock(_component_app_footer, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_app_modal)
  ], 64))
}