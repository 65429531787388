import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!

  return (_openBlock(), _createBlock(_component_app_input, {
    value: _ctx.emailValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailValue) = $event)),
    valid: _ctx.emailValueValid,
    "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailValueValid) = $event)),
    mode: "email",
    label: "Почта для отправки чека",
    required: _ctx.clubConfig ? _ctx.clubConfig.emailRequired : false,
    outlined: ""
  }, null, 8, ["value", "valid", "required"]))
}