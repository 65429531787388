
import { defineComponent } from 'vue'
import PaymentRadioGroup from '@/components/home/payment/components/RadioGroup.vue'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: 'PaymentModal',
  components: {
    'app-payment-radio-group': PaymentRadioGroup
  },
  computed: {
    ...mapGetters('payment', [
      'draft',
      'paymentMethods'
    ])
  },
  methods: {
    ...mapActions('payment', [
      'getPayMethods'
    ])
  },
  async mounted () {
    await this.getPayMethods()
  }
})
