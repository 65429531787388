
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Tooltip from '@/components/UI/buttons/Tooltip.vue'
import SumInput from '@/components/home/payment/components/Sum.vue'

export default defineComponent({
  name: 'OrderDraftMain',
  components: {
    'app-tooltip': Tooltip,
    'app-payment-sum': SumInput
  },
  computed: {
    ...mapGetters('order', [
      'orderTotal',
      'orderEditable'
    ]),
    ...mapGetters('payment', [
      'draftGlobal',
      'draftComplimentary',
      'draftHappyBirthday',
      'draft'
    ]),
    ...mapGetters('loyalty', [
      'clubConfig',
      'currencySymbol'
    ]),
    ...mapGetters('paymentTypes', [
      'paymentType'
    ])
  }
})
