
import { defineComponent } from 'vue'
import Link from '@/components/UI/links/Link.vue'

export default defineComponent({
  name: 'NotFoundError',
  components: {
    'app-link': Link
  }
})
