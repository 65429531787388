import { authApi } from '@/api/auth'

const checkPhone = (phone: any) => {
  const res = /[0-9]{11}/g.test(phone)
  return res
}

const getDefaultState = () => {
  return {
    isAuthorized: !!localStorage.getItem('bmsCheckoutRefresh') && checkPhone(localStorage.getItem('bmsCheckoutUser')),
    isSecretAuthorized: false,
    isInProcess: false,
    login: localStorage.getItem('bmsCheckoutUser') || '',
    phone: '',
    email: '',
    name: '',
    authType: 'phone'
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    REGISTER (state: any, data: any) {
      const token = localStorage.getItem('bmsCheckoutToken')
      const refresh = localStorage.getItem('bmsCheckoutRefresh')
      const user = localStorage.getItem('bmsCheckoutUser')
      const checkPhoneNumber = data.login.match(/^\d{11}/)
      // Если в localStorage уже есть пользователь и у нового пользователя не указан номер телефона в логине
      // оставляем все как было
      if (token && refresh && !checkPhoneNumber) {
        return
      }
      // Если в логине записан номер телефона, считаем что пользователь авторизован
      if (checkPhoneNumber) {
        state.isAuthorized = true
        state.login = data.login
      }
      // Записываем данные нового пользователя в локалсторадж
      localStorage.setItem('bmsCheckoutUser', data.login)
      localStorage.setItem('bmsCheckoutToken', data.token)
      localStorage.setItem('bmsCheckoutRefresh', data.refresh)
    },
    LOGIN (state: any, data: any) {
      localStorage.setItem('bmsCheckoutUser', data.login)
      localStorage.setItem('bmsCheckoutToken', data.token)
      localStorage.setItem('bmsCheckoutRefresh', data.refresh)

      state.isAuthorized = true
      state.isSecretAuthorized = false
      state.login = data.login
      state.phone = data.phone || ''
      state.email = data.email || ''
      state.name = data.name || ''
      state.authType = data.authType || 'phone'
    },
    LOGOUT (state: any) {
      localStorage.removeItem('bmsCheckoutUser')
      localStorage.removeItem('bmsCheckoutToken')
      localStorage.removeItem('bmsCheckoutRefresh')

      state.isAuthorized = false
      state.isSecretAuthorized = false
      state.login = ''
      state.phone = ''
      state.email = ''
      state.name = ''
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    },
    SECRET_AUTHORIZE (state: any) {
      state.isSecretAuthorized = true
    }
  },
  actions: {
    async checkUserToken ({ commit, dispatch, state }: any) {
      const token = localStorage.getItem('bmsCheckoutToken')
      const refresh = localStorage.getItem('bmsCheckoutRefresh')
      const user = localStorage.getItem('bmsCheckoutUser')

      if (!token || !refresh || !user) {
        const res = await authApi.register()

        commit('REGISTER', res.data.data)
      }

      return true
    },
    async sendToken ({ commit }: any, phone: string): Promise<any> {
      const res = await authApi.sendToken({ phone })

      return res
    },
    async sendEmailToken ({ commit }: any, email: string): Promise<any> {
      const res = await authApi.sendEmailToken({ email })
      console.log('sendEmailToken res', res)

      return res
    },
    async approveToken ({ commit, dispatch }: any, data: any): Promise<any> {
      const { phone, code, email } = data
      let { name } = data
      if (name) {
        name = name.charAt(0).toUpperCase() + name.slice(1)
      }
      const res = await authApi.approveToken({ phone, email, code, name })
      commit('LOGIN', res.data.data)

      // await dispatch('payment/createDraft', null, { root: true })
      // await dispatch('user/getBalance', null, { root: true })

      return res
    },
    async logout ({ commit, dispatch, state }: any, call = true) {
      try {
        const login = state.login

        if (login) {
          return await authApi.logout({ login })
        }
      } catch (e) {
        console.log('e', e)
        throw e
      } finally {
        commit('LOGOUT')
        dispatch('resetState', null, { root: true })

        if (call) {
          const isExist = await dispatch('auth/checkUserToken', null, { root: true })
          if (!isExist) {
            commit('SET_LOADING_STATUS', 'error', { root: true })
          }
          await dispatch('info/getInfo', null, { root: true })
        }
      }
    }
  },
  getters: {
    login: (state: any): string => state.login,
    email: (state: any): string => state.email,
    isAuthorized: (state: any) => state.isAuthorized,
    isSecretAuthorized: (state: any) => state.isSecretAuthorized,
    authType: (state: any) => state.authType
  }
}
