
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import Button from '@/components/UI/buttons/Button.vue'

export default defineComponent({
  name: 'Login',
  components: {
    'app-button': Button
  },
  props: {
    isAuthorized: {
      type: Boolean,
      required: true
    },
    isCreated: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', [
      'login',
      'email',
      'authType'
    ]),
    text () {
      if (this.isAuthorized) {
        return 'Вы авторизованы'
      } else if (this.isCreated) {
        return 'Выполните вход\n для траты бонусов'
      } else {
        return 'Авторизоваться'
      }
    }
  },
  data: () => ({
  })
})
