
import { defineComponent } from 'vue'
import OrderItems from '@/components/home/order/components/Items.vue'
import OrderDraft from '@/components/home/payment/components/draft/Draft.vue'
import OrderPromocode from '@/components/home/order/components/Promocode.vue'
import OrderForm from '@/components/home/order/components/form/Form.vue'
import OrderSubheader from '@/components/home/order/components/Subheader.vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'Order',
  components: {
    'app-order-items': OrderItems,
    // 'app-order-draft': OrderDraft,
    // 'app-order-promocode': OrderPromocode,
    // 'app-order-form': OrderForm,
    'app-order-subheader': OrderSubheader
  },
  props: {},
  computed: {
    ...mapGetters('order', [
      'orderId'
    ])
  },
  methods: {
    ...mapActions('modal', [
      'toggleModal'
    ]),
    openGoodsList () {
      this.toggleModal({
        name: 'goods',
        value: true
      })
    }
  }
})
