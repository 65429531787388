import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "sbp-pay-button",
    disabled: _ctx.disabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pay')))
  }, [
    _createElementVNode("span", {
      innerHTML: require(`@/assets/img/icons/SBP-white.svg`)
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}