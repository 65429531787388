/* eslint-disable  @typescript-eslint/no-explicit-any */
import { paymentApi } from '@/api/payment'
import Payment from '@/models/payment.model'
import PaymentMethod from '@/models/paymentMethod.model'
import Draft from '@/models/draft.model'
import CompleteDraft from '@/models/completeDraft.model'
import { ActionContext } from 'vuex'
import isMobile from '@/mixins/mobileCheck'
import router from '@/router'

const getDefaultState = () => {
  return {
    isPaymentAvailable: true,
    paymentMethods: [],
    isSpendBonus: true,
    promocodeConfirmed: false,
    draft: {
      accountChanges: []
    },
    completeResponse: '',
    completeDraftInterval: 0,
    sbpQrURL: ''
  }
}

const payment = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_PAYMENT_AVAILABLE (state: Payment, data: boolean): void {
      state.isPaymentAvailable = data
    },
    SET_PAYMENT_METHODS (state: Payment, data: PaymentMethod[]): void {
      state.paymentMethods = data
    },
    SET_SPEND_BONUS (state: Payment, data: boolean): void {
      state.isSpendBonus = data
    },
    SET_PROMOCODE_CONFIRMED (state: Payment, data: boolean): void {
      state.promocodeConfirmed = data
    },
    CREATE_DRAFT (state: Payment, data: Draft): void {
      state.draft = data
    },
    SET_COMPLETE_RESPONSE (state: Payment, data: CompleteDraft): void {
      state.completeResponse = data
    },
    SET_SBPQRURL (state: Payment, url: string):void {
      state.sbpQrURL = url
    },
    RESET_STATE (state: Payment): void {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    checkPaymentAvailable ({ commit, getters, rootGetters }: ActionContext<Payment, any>): void {
      if (rootGetters['order/bonusOnly'] && getters.draftExtra.spend > 0) {
        commit('SET_PAYMENT_AVAILABLE', false)
      } else {
        commit('SET_PAYMENT_AVAILABLE', true)
      }
    },
    setSpendBonus ({ commit }: ActionContext<Payment, any>, data: string): void {
      commit('SET_SPEND_BONUS', data)
    },
    setPromocode ({ commit }: ActionContext<Payment, any>, data: string): void {
      commit('SET_PROMOCODE', data)
    },
    setPromocodeConfirmed ({ commit }: ActionContext<Payment, any>, data: boolean): void {
      commit('SET_PROMOCODE_CONFIRMED', data)
    },
    async createDraft ({ commit, dispatch, state, rootGetters, getters }: ActionContext<Payment, any>, promocode?: string): Promise<any> {
      if (rootGetters['order/orderCompleted']) {
        return
      }
      commit('SET_LOADING_STATUS', 'pending', { root: true })
      try {
        const data = {
          paymentType: rootGetters['paymentTypes/paymentType'],
          isSpendBonus: state.isSpendBonus,
          promocode,
          total: rootGetters['order/orderTotal'],
          goods: rootGetters['order/orderGoods'],
          serviceFee: getters.draft.serviceFee
        }
        const res = await paymentApi.createDraft(data)

        if (promocode) {
          commit('SET_PROMOCODE_CONFIRMED', true)
        }

        commit('CREATE_DRAFT', res.data.data)

        if (res.data.data.possibleToSpend) {
          commit('auth/SECRET_AUTHORIZE')
        }

        await dispatch('checkPaymentAvailable')

        commit('SET_LOADING_STATUS', 'ok', { root: true })
      } catch (e: any) {
        commit('SET_PROMOCODE_CONFIRMED', false)
        commit('SET_LOADING_STATUS', 'error', { root: true })

        if (promocode) {
          // если это обновление драфта для перерасчета с промокодом
          // отображаем ошибку в попапе
          throw e
        } else {
          // если это обновление драфта не для перерасчета с промокодом
          // отображаем глобальную ошибку
          if (e && e.data && e.data.error && e.data.error.code === 2104) {
            await dispatch('error/toggleError', {
              name: 'draft',
              value: true
            }, { root: true })
          } else if (e && e.data && e.data.error && e.data.error.code === 2109) {
            dispatch('modal/toggleModal', { name: 'auth', value: true, permanent: true }, { root: true })
          } else {
            await dispatch('error/toggleError', {
              name: 'internal',
              value: true
            }, { root: true })
          }
        }
      }
    },
    async processDraft ({ commit, dispatch, rootGetters }: ActionContext<Payment, any>, token: string): Promise<any> {
      try {
        commit('SET_LOADING_STATUS', 'pending', { root: true })

        const paymentsWithToken = ['GOOGLE', 'APPLE']

        const bmsCheckoutUser = localStorage.getItem('bmsCheckoutUser') || ''
        const bmsCheckoutToken = localStorage.getItem('bmsCheckoutToken') || ''
        const bmsCheckoutRefresh = localStorage.getItem('bmsCheckoutRefresh') || ''

        const href = `${window.location.origin}/finish${window.location.search}`

        const successUrl = `${href}&bmsCheckoutUser=${bmsCheckoutUser}&bmsCheckoutToken=${bmsCheckoutToken}&bmsCheckoutRefresh=${bmsCheckoutRefresh}`

        interface ProcessDraftDto {
          paymentType: string;
          paymentToken: string;
          email: string;
          successUrl: string;
          currency?: string;
        }

        const data: ProcessDraftDto = {
          paymentType: rootGetters['paymentTypes/paymentType'],
          paymentToken: paymentsWithToken.indexOf(rootGetters['paymentTypes/paymentType']) !== -1 ? token : '',
          email: rootGetters['user/email'],
          successUrl
        }

        if (rootGetters['loyalty/clubConfig'].currency && rootGetters['paymentTypes/paymentType'] === 'NEW_CARD') {
          data.currency = rootGetters['loyalty/clubConfig'].currency
        }

        const res = await paymentApi.processDraft(data)

        commit('SET_COMPLETE_RESPONSE', res.data.data)

        let url = res.data.data.returnUrl

        if (res.data.data.formUrl) {
          url = res.data.data.formUrl
        }

        if (res.data.data.completed) {
          // редиректим пользователя на финальную страницу для проверки результата оплаты
          await router.push({ path: '/finish', query: { id: router.currentRoute.value.query.id, furcaToken: router?.currentRoute?.value?.query?.furcaToken } })
        } else {
          if (data.paymentType === 'SBP_QR') {
            await dispatch('SBPPaymentHandler', url)
          } else {
            window.location.replace(url)
          }
        }

        commit('SET_LOADING_STATUS', 'ok', { root: true })
      } catch (e: any) {
        commit('SET_LOADING_STATUS', 'error', { root: true })

        if (e && e.data && e.data.error && e.data.error.code === 2109) {
          dispatch('modal/toggleModal', { name: 'auth', value: true, permanent: true }, { root: true })
        } else {
          await dispatch('error/toggleError', {
            name: 'internal',
            value: true
          }, { root: true })
        }
      }
    },
    async completeDraft ({ commit, dispatch }: ActionContext<Payment, any>): Promise<any> {
      try {
        commit('SET_LOADING_STATUS', 'pending', { root: true })

        const res = await paymentApi.completeDraft()

        commit('SET_COMPLETE_RESPONSE', res.data.data)

        if (res.data.data.completed) {
          if (res.data.data.returnUrl) {
            window.location.replace(res.data.data.returnUrl)
          } else {
            commit('SET_LOADING_STATUS', 'ok', { root: true })
          }
          dispatch('modal/toggleModal', { name: 'sbp', value: false, permanent: false }, { root: true })
        } else {
          commit('SET_LOADING_STATUS', 'pending', { root: true })
        }
      } catch (e: any) {
        commit('SET_LOADING_STATUS', 'error', { root: true })
        if (e && e.data && e.data.error && e.data.error.code === 2109) {
          dispatch('modal/toggleModal', { name: 'auth', value: true, permanent: true }, { root: true })
        } else {
          await dispatch('error/toggleError', {
            name: 'internal',
            value: true
          }, { root: true })
        }
      }
    },
    async sendCheque (_: any, email: string): Promise<any> {
      return paymentApi.sendCheque(email)
    },
    SBPPaymentHandler ({ commit, dispatch, rootGetters }: ActionContext<Payment, any>, url: string): void {
      // заменяем https на код банка для перехода сразу в выбранный банк
      url = url.replace('https', rootGetters['paymentTypes/SBPBank'].schema)

      if (isMobile()) {
        // для мобильных имитируем клик для автоматического перехода в приложение
        if (window.top?.location) {
          // IOS
          window.top.location.href = url
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.setAttribute('style', 'display: none')
          a.href = 'popup:' + url
          a.click()
          document.body.removeChild(a)
        }
      } else {
        // для десктопа открываем новую страницу с куаром
        // url = `${window.location.origin}/sbp-qr?url=${url}`
        // window.open(url, '_target')
        commit('SET_SBPQRURL', url)
        dispatch('modal/toggleModal', { name: 'sbp', value: true, permanent: true }, { root: true })
      }

      localStorage.setItem('BMSCheckoutSBP', JSON.stringify(rootGetters['paymentTypes/SBPBank']))
      // редиректим пользователя на финальную страницу для проверки результата оплаты
      router.push({ path: '/finish', query: { id: router.currentRoute.value.query.id, furcaToken: router?.currentRoute?.value?.query?.furcaToken } })
    },
    async pay ({ commit, dispatch }: ActionContext<Payment, any>, data: any): Promise<any> {
      try {
        commit('SET_LOADING_STATUS', 'pending', { root: true })
        const res = await paymentApi.pay(data)
        if (res.data.data.formUrl) {
          await router.push({ path: '/', query: { id: res.data.data.formUrl.split('=')[1], furcaToken: data.furcaToken } })

          await dispatch('info/getInfo', null, { root: true })
        }
      } catch (e:any) {
        commit('SET_LOADING_STATUS', 'error', { root: true })
        console.log(e)
        if (e.data && e.data.error && e.data.error.code === 2108) {
          await dispatch('error/toggleError', {
            name: 'wrong-config',
            value: true
          }, { root: true })
        } else if (e && e.data && e.data.error && e.data.error.code === 2109) {
          dispatch('modal/toggleModal', { name: 'auth', value: true, permanent: true }, { root: true })
        } else if (e?.data?.error?.code === 2110) {
          await dispatch('error/toggleError', {
            name: 'already-payed',
            value: true
          }, { root: true })
        } else {
          await dispatch('error/toggleError', {
            name: 'internal',
            value: true
          }, { root: true })
        }
      }
    },
    async getPayMethods ({ commit }: ActionContext<any, any>, data: any): Promise<any> {
      try {
        const { data } = await paymentApi.getPayMethods()
        if (data && data.data && data.data.data && data.data.data.methods) {
          commit('SET_PAYMENT_METHODS', data.data.data.methods)
        }
      } catch (e:any) {
        console.log(e)
      }
    }
  },
  getters: {
    isPaymentAvailable: (state: Payment) => state.isPaymentAvailable,
    paymentMethods: (state: Payment) => state.paymentMethods,
    isSpendBonus: (state: Payment) => state.isSpendBonus,
    promocodeConfirmed: (state: Payment) => state.promocodeConfirmed,
    draft: (state: Payment) => state.draft,
    draftAccount: (state: Payment) => (accountType: string) => {
      const account = state.draft.accountChanges.find(el => el.accountType === accountType)
      if (account) {
        return account
      } else {
        return {
          accountType: accountType,
          spend: 0,
          earn: 0
        }
      }
    },
    draftExtra: (state: Payment, getters: any) => {
      return getters.draftAccount('EXTRA')
    },
    draftGlobal: (state: Payment, getters: any) => {
      return getters.draftAccount('GLOBAL')
    },
    draftComplimentary: (state: Payment, getters: any) => {
      return getters.draftAccount('COMPLIMENTARY')
    },
    draftHappyBirthday: (state: Payment, getters: any) => {
      return getters.draftAccount('HAPPYBIRTHDAY')
    },
    completeResponse: (state: Payment) => state.completeResponse,
    getSbpQrURL: (state: Payment) => state.sbpQrURL
  }
}

export default payment
