
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logo',
  props: {
    image: {
      type: String,
      required: false
    },
    title: {
      type: String || null,
      required: false,
      default: 'Программы лояльности под ключ'
    }
  }
})
