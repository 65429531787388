import User from '@/models/user.model'
import Balance from '@/models/balance.model'
import { userApi } from '@/api/user'

const getDefaultState = () => {
  return {
    balance: 0,
    email: '',
    emailValid: true,
    cardNum: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_BALANCE (state: User, data: Balance[]) {
      const global = data.find(el => el.accountType === 'GLOBAL')
      state.balance = global ? global.availableAmount : 0
    },
    SET_EMAIL (state: User, data: string) {
      state.email = data
    },
    SET_EMAIL_VALID (state: User, data: boolean) {
      state.emailValid = data
    },
    RESET_STATE (state: User) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async getBalance ({ commit }: any): Promise<any> {
      const res = await userApi.getBalance()
      commit('SET_BALANCE', res.data.data)
    },
    setEmail ({ commit }: any, data: string) {
      commit('SET_EMAIL', data)
    },
    setEmailValid ({ commit }: any, data: boolean) {
      commit('SET_EMAIL_VALID', data)
    }
  },
  getters: {
    balance: (state: User): number => state.balance,
    email: (state: User): string => state.email,
    emailValid: (state: User): boolean => state.emailValid,
    cardNum: (state: User): string => state.cardNum
  }
}
