/* eslint-disable */
import IMask from 'imask'

const IMaskDirective = {
  name: 'imask',

  beforeMount (el: any, { value: options }: any) {
    if (!options) return

    initMask(el, options)
  },

  updated (el: any, { value: options }: any) {
    if (options) {
      if (el.maskRef) {
        el.maskRef.updateOptions(options)
        if (el.value !== el.maskRef.value) el.maskRef._onChange()
      } else initMask(el, options)
    } else {
      destroyMask(el)
    }
  },

  unmounted (el: any) {
    destroyMask(el)
  }
}

function fireEvent (el: any, eventName: any, data: any) {
  const e = document.createEvent('CustomEvent')
  e.initCustomEvent(eventName, true, true, data)
  el.dispatchEvent(e)
}

function initMask (el: any, opts: any) {
  el.maskRef = IMask(el, opts)
    .on('accept', () => fireEvent(el, 'accept', el.maskRef))
    .on('complete', () => fireEvent(el, 'complete', el.maskRef))
}

function destroyMask (el: any) {
  if (el.maskRef) {
    el.maskRef.destroy()
    delete el.maskRef
  }
}

export default IMaskDirective
