import Loyalty from '@/models/loyalty.model'

const getDefaultState = () => {
  return {
    club: {
      id: '1',
      config: {
        emailRequired: false,
        onlyPayment: false,
        promoCodesExist: false,
        paymentTypes: [],
        policy: {
          termsOfPurchase: '',
          personalDataUsage: '',
          personalDataPolicy: '',
          personalDataOferta: ''
        },
        currency: 'RUB',
        logo: '',
        authMethods: []
      }
    },
    retailPoint: {
      id: '125690'
    }
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_CLUB (state: Loyalty, data: any) {
      const { id, title, config } = data
      state.club = { id, title, config }
    },
    SET_RETAIL_POINT (state: Loyalty, data: any) {
      const { id, title, address } = data
      state.retailPoint = { id, title, address }
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
  },
  getters: {
    clubTitle: (state: Loyalty) => state.club.title,
    clubConfig: (state: Loyalty) => state.club.config,
    clubId: (state: Loyalty) => state.club.id,
    retailPointId: (state: Loyalty) => state.retailPoint.id,
    retailPointTitle: (state: Loyalty) => state.retailPoint.title,
    retailPointAddress: (state: Loyalty) => state.retailPoint.address,
    isPromoCodesExists: (state: Loyalty) => state.club.config && state.club.config.promoCodesExist,
    emailRequired: (state: Loyalty) => state.club.config && state.club.config.emailRequired,
    currency: (state: Loyalty) => (state.club.config && state.club.config.currency) || 'RUB',
    currencySymbol: (state: Loyalty) => {
      let symbol = ''
      switch (state.club.config.currency) {
        case 'AMD':
          symbol = '֏'
          break
        default:
          symbol = '₽'
      }
      return symbol
    },
    logo: (state: Loyalty) => state.club.config && state.club.config.logo
  }
}
