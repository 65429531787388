
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HeightCollapsible from 'vue-height-collapsible/vue3'

export default defineComponent({
  name: 'OrderItems',
  components: {
    // HeightCollapsible
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('order', [
      'orderGoods'
    ]),
    ...mapGetters('loyalty', [
      'currencySymbol'
    ])
  },
  methods: {
    open () {
      this.$emit('click')
    }
  }
})
