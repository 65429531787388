
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'
import Checkbox from '@/components/UI/inputs/Checkbox.vue'
import Button from '@/components/UI/buttons/Button.vue'
import Link from '@/components/UI/links/Link.vue'
import Spinner from '@/components/UI/Spinner.vue'
import Timer from '@/components/auth/components/Timer.vue'

// Index Signature
interface errors {
  [field: string]: boolean;
}

export default defineComponent({
  name: 'Auth',
  components: {
    'app-input': Input,
    'app-checkbox': Checkbox,
    'app-button': Button,
    'app-link': Link,
    'app-spinner': Spinner,
    'app-timer': Timer
  },
  data: () => ({
    isFinishRegistration: false,
    form: {
      name: '',
      phone: '',
      code: '',
      agreed: false,
      redirectPage: ''
    },
    phoneValid: false,
    nameValid: false,
    codeValid: false,
    sended: false, // true, если первый запрос на отправку токена отправлен успешно
    resended: false, // true, если повторный запрос на отправку токена отправлен успешно
    loader: false,
    errors: {
      server: false,
      code: false
    } as errors // it`s needs index signature for loop through the object
  }),
  mounted () {
    if (this.isAuthorized) this.$router.push(`/finish?id=${this.$route.query.id}`)
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthorized'
    ]),
    ...mapGetters('user', [
      'balance'
    ]),
    ...mapGetters('loyalty', [
      'clubTitle',
      'clubConfig'
    ]),
    ...mapGetters('order', [
      'bonusOnly'
    ]),
    personalDataPolicy () {
      return this.clubConfig.policy.personalDataPolicy
    },
    personalDataOferta () {
      return this.clubConfig.policy.personalDataOferta
    },
    formValid () {
      return this.form.name.trim() && this.form.phone && this.phoneValid && this.form.agreed
    }
  },
  watch: {
    'form.code' () {
      this.checkConfirmCode()
    },
    'form.phone' () {
      this.sended = false
      this.isFinishRegistration = false
    },
    form: {
      handler () {
        this.clearErrors()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('auth', [
      'sendToken',
      'approveToken'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    async sendConfirmCode (): Promise<any> {
      this.resended = false
      this.form.code = ''
      this.clearErrors()
      try {
        this.loader = true
        await this.sendToken(this.form.phone)
        this.sended = true
        this.resended = true
      } catch (e) {
        this.errors.server = true
      } finally {
        this.loader = false
      }
    },
    async checkConfirmCode () {
      try {
        if (this.form.code.length !== 5 && !this.codeValid) {
          return
        }
        this.loader = true
        const res = await this.approveToken({ phone: this.form.phone, code: this.form.code, name: this.form.name })
        this.form.name = res.data?.data?.name
        this.form.redirectPage = res.data?.data?.redirectPage
        this.isFinishRegistration = true
      } catch (e: any) {
        if (e?.data?.error?.code === 2101) {
          this.errors.code = true
        } else {
          this.errors.server = true
        }
      } finally {
        this.loader = false
      }
    },
    clearErrors () {
      for (const error in this.errors) {
        this.errors[error] = false
      }
    }
  }
})
