
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'

export default defineComponent({
  name: 'PaymentForm',
  components: {
    'app-input': Input
  },
  computed: {
    ...mapGetters('loyalty', [
      'clubConfig'
    ]),
    ...mapGetters('user', [
      'email',
      'emailValid'
    ]),
    emailValue: {
      get () {
        return this.email
      },
      set (value: string) {
        this.setEmail(value)
      }
    },
    emailValueValid: {
      get () {
        return this.emailValid
      },
      set (value: boolean) {
        this.setEmailValid(value)
      }
    }
  },
  data: () => ({}),
  methods: {
    ...mapActions('user', [
      'setEmail',
      'setEmailValid'
    ])
  }
})
