import { server } from './index'
import { AxiosResponse } from 'axios'

export interface UsersDto {
  token?: string;
  retailPointId?: string;
  terminalCode?: string;
  idLoyaltyProgram: string;
  payServiceLogin?: string;
}

const furcaApi = {
  /**
   * Get info about user from furca token
   * @returns {Promise<AxiosResponse<T>>}
   */
  getFurcaInfo (token: string): Promise<AxiosResponse> {
    return server.get('/furca', { params: { token } })
  },

  /**
   * Post info about user from furca token
   * @returns {Promise<AxiosResponse<{token: string}>>}
   */
  postFurcaInfo (usersDto: UsersDto): Promise<AxiosResponse<{data: string}>> {
    return server.post('/furca', usersDto)
  },

  /**
   * Bulk Notifications from furcaToken
   * @returns {Promise<AxiosResponse<{status: string}>>}
   */
  bulkNotification ({ messages, token, login }: { messages: {message: string}[], token?: string, login?: string }): Promise<AxiosResponse<{data: string}>> {
    return server.post('/furca/bulk-notification', { messages, token, login })
  }

}

export { furcaApi }
