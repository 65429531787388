
import { defineComponent } from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Input from '@/components/UI/inputs/Input.vue'

export default defineComponent({
  name: 'SumInput',
  components: {
    'app-input': Input
  },
  created () {
    if (this.sumValue === 0) {
      this.SET_TOTAL_VALID(false)
    }
  },
  computed: {
    ...mapGetters('order', [
      'orderTotal',
      'orderTotalValid'
    ]),
    ...mapGetters('loyalty', [
      'currencySymbol'
    ]),
    ...mapGetters(['loadingStatus']),
    sumValue: {
      get () {
        return this.orderTotal
      },
      set (value: string) {
        this.SET_TOTAL(value)
      }
    },
    sumValueValid: {
      get () {
        return this.orderTotalValid
      },
      set (value: boolean) {
        this.SET_TOTAL_VALID(value)
      }
    }
  },
  data: () => ({
    baseSum: 0
  }),
  methods: {
    // ...mapActions('user', [
    //   'setEmail',
    //   'setEmailValid'
    // ])
    ...mapMutations('order', [
      'SET_TOTAL',
      'SET_TOTAL_VALID'
    ]),
    ...mapActions('payment', [
      'createDraft'
    ]),
    onBlur () {
      if (this.sumValueValid && this.loadingStatus !== 'pending' && this.baseSum !== this.sumValue) {
        this.baseSum = this.sumValue
        this.createDraft()
      }
    }
  }
})
