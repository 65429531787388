
import { defineComponent } from 'vue'
import Spinner from '@/components/UI/Spinner.vue'
import Overlay from '@/components/UI/Overlay.vue'

export default defineComponent({
  name: 'Preloader',
  components: {
    'app-spinner': Spinner,
    'app-overlay': Overlay
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
})
