
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Button from '@/components/UI/buttons/Button.vue'
import Internal from '@/components/error/components/Internal.vue'
import NotFound from '@/components/error/components/NotFound.vue'
import AlreadyPayed from '@/components/error/components/AlreadyPayed.vue'
import PaymentType from '@/components/error/components/PaymentType.vue'
import Draft from '@/components/error/components/Draft.vue'
import WrongConfig from '@/components/error/components/WrongConfig.vue'

export default defineComponent({
  name: 'Error',
  components: {
    'app-button': Button,
    'app-internal': Internal,
    'app-not-found': NotFound,
    'app-payment-type': PaymentType,
    'app-already-payed': AlreadyPayed,
    'app-wrong-config': WrongConfig,
    'app-draft': Draft
  },
  computed: {
    ...mapGetters('error', [
      'name',
      'value'
    ]),
    showError: {
      get (): boolean {
        return this.value
      },
      set (value: boolean): void {
        this.toggleError({
          name: '',
          value
        })
      }
    }
  },
  methods: {
    ...mapActions('error', [
      'toggleError'
    ]),
    close () {
      this.showError = false
    },
    reload () {
      location.reload()
    }
  }
})
