// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import axios from 'axios'
import store from '../store'
import helpers from '@/helpers'

let baseURL
if (process.env.NODE_ENV === 'development') baseURL = 'http://localhost:8085'
if (process.env.VUE_APP_URL) baseURL = process.env.VUE_APP_URL

async function updateRefresh (): Promise<void> {
  try {
    const login = localStorage.getItem('bmsCheckoutUser')
    const refresh = localStorage.getItem('bmsCheckoutRefresh')
    const res = await server.post('/auth/refresh', { login, refresh })
    localStorage.setItem('bmsCheckoutToken', res.data.data.token)
    localStorage.setItem('bmsCheckoutRefresh', res.data.data.refresh)
  } catch (e) {
    await store.dispatch('auth/logout')
  }
}

const token = Buffer.from(`${process.env.VUE_APP_USERNAME}:${process.env.VUE_APP_PASSWORD}`).toString('base64')
const server = axios.create({
  baseURL,
  timeout: 600000,
  headers: {
    Authorization: `Basic ${token}`
  }
})

server.interceptors.request.use((request: any): any => {
  const publicMethods = ['auth/registration', '/auth/refresh', 'auth/logout', '/payment', '/furca', '/furca/bulk-notification']
  const token = localStorage.getItem('bmsCheckoutToken')
  if (token && publicMethods.indexOf(request.url) === -1) {
    request.headers.Authorization = `Bearer ${token}`
  }

  // методы не стреляющие во внешние сервисы (не требуется айди заказа)
  const innerMethods = ['/auth/refresh', 'auth/logout', '/apple/validate', '/payment', '/furca', '/furca/bulk-notification']
  // получаем айди заказа из ссылки
  const query = helpers.methods.parseUrlQuery()
  // console.log('query', query)
  if (innerMethods.indexOf(request.url) === -1 && !query.id) {
    // Если запрос требует наличия orderId, но он не передан, прерываем запрос
    store.commit('SET_LOADING_STATUS', 'error')
    return false
  }
  if (innerMethods.indexOf(request.url) === -1 && !request.url.includes(query.id)) {
    // добавляем параметром айди заказа
    request.url += `/${query.id}`
  }

  return request
})

server.interceptors.response.use(undefined, async (error: any): Promise<any> => {
  console.log('e', error)
  if (error.response) {
    const originalRequest = error.response.config

    if (error.response && error.response.status === 401) {
      if (error.response.data.message === 'jwt expired' || error.response.data.message === 'jwt invalid') {
        await updateRefresh()
        return await server(originalRequest)
      } else if (error.response.data.message === 'bad user') {
        await store.dispatch('auth/logout')
        return await server(originalRequest)
      }
    } else {
      throw error.response || error
    }
  } else {
    throw error
  }
})

export { server, updateRefresh }
