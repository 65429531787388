
import { defineComponent } from 'vue'
import Input from '@/components/UI/inputs/Input.vue'
import json from '@/assets/json/nspk.json'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SBPBank from '@/models/SBPBank.model'

export default defineComponent({
  name: 'BanksList',
  components: {
    'app-input': Input
  },
  data: () => ({
    filter: ''
  }),
  created () {
    this.filter = this.SBPBank ? this.SBPBank.bankName : ''
  },
  computed: {
    ...mapGetters('paymentTypes', [
      'SBPBank'
    ]),
    list () {
      if (this.filter.length) {
        return json.dictionary.filter((el) => {
          return el.bankName.toLowerCase().includes(this.filter.toLowerCase())
        })
      }
      return json.dictionary
    }
  },
  watch: {},
  methods: {
    ...mapMutations('paymentTypes', [
      'SET_SBP_BANK'
    ]),
    ...mapActions('modal', [
      'toggleModal'
    ]),
    selectBank (e: SBPBank) {
      this.SET_SBP_BANK(e)
      this.toggleModal({
        name: 'banks',
        value: false
      })
    }
  }
})
