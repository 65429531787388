
import { defineComponent } from 'vue'
import Spinner from '@/components/UI/Spinner.vue'

export default defineComponent({
  name: 'Button',
  components: {
    'app-spinner': Spinner
  },
  props: {
    size: {
      type: String,
      required: false,
      default: 'm'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String, // filled, outline
      required: false,
      default: 'filled'
    }
  },
  computed: {
    isDisabled () {
      return this.disabled || this.loading
    }
  }
})
