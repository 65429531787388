import { server } from './index'
import { AxiosResponse } from 'axios'

const authApi = {
  /**
   * Register user
   * @returns {Promise<AxiosResponse<T>>}
   */
  register (): Promise<AxiosResponse> {
    return server.get('auth/registration')
  },
  /**
   * Send user sms token
   * @param dto
   * @param dto.phone string
   * @returns {Promise<AxiosResponse<T>>}
   */
  sendToken (data: any): Promise<AxiosResponse> {
    return server.post('auth/token', data)
  },
  /**
   * Send user email token
   * @param dto
   * @param dto.email string
   * @returns {Promise<AxiosResponse<T>>}
   */
  sendEmailToken ({ email }: any): Promise<AxiosResponse> {
    return server.post('/auth/email', { email })
  },
  /**
   * Approve sms token
   * @param data
   * @param data.id string
   * @param data.phone string
   * @param data.email string
   * @param data.code string   * @returns {Promise<AxiosResponse<T>>}
   */
  approveToken (data: any): Promise<AxiosResponse> {
    return server.post('auth/approving', data)
  },
  /**
   * Logout user
   * @param data
   * @param data.login String
   * @returns {Promise<AxiosResponse<T>>}
   */
  logout (data: any): Promise<AxiosResponse> {
    return server.post('auth/logout', data)
  }
}

export { authApi }
