
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ButtonLink',
  props: {
    size: {
      type: String,
      required: false,
      default: 's',
      validator (value: string) {
        // Значение должно соответствовать одной из этих строк
        return ['s', 'm', 'l'].indexOf(value) !== -1
      }
    },
    color: {
      type: String,
      required: false,
      default: 'accent',
      validator (value: string) {
        // Значение должно соответствовать одной из этих строк
        return ['accent', 'success'].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    arrow: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
