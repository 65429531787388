const getDefaultState = () => {
  return {
    googleMerchantId: '',
    googleMerchantName: '',
    appleMerchantId: '',
    appleMerchantName: ''
  }
}

const config = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    'SET_CONFIG' (state: any) {
      state.googleMerchantId = process.env.VUE_APP_GOOGLE_MERCHANT_ID
      state.googleMerchantName = process.env.VUE_APP_GOOGLE_MERCHANT_NAME
      state.appleMerchantId = process.env.VUE_APP_APPLE_MERCHANT_ID
      state.appleMerchantName = process.env.VUE_APP_APPLE_MERCHANT_NAME
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    setConfig ({ commit }: any) {
      commit('SET_CONFIG')
    }
  },
  getters: {
    getGoogleMerchantId: (state: any) => state.googleMerchantId,
    getGoogleMerchantName: (state: any) => state.googleMerchantName,
    getAppleMerchantId: (state: any) => state.appleMerchantId,
    getAppleMerchantName: (state: any) => state.appleMerchantName
  }
}

export default config
