
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Spinner',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'dark',
      validator (value: string) {
        // Значение должно соответствовать одной из этих строк
        return ['dark', 'light'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      requied: false,
      default: 's',
      validator (value: string) {
        // Значение должно соответствовать одной из этих строк
        return ['s', 'm', 'l'].indexOf(value) !== -1
      }
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
