import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked", "required", "disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "checkbox__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['checkbox', { 'disabled': _ctx.disabled }])
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["checkbox__input", {
        'checked': _ctx.value
      }])
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: _ctx.value,
        required: _ctx.required,
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateInput && _ctx.updateInput(...args)))
      }, null, 40, _hoisted_1),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", {
            class: "checkbox__input-icon",
            innerHTML: require('@/assets/img/icons/ok.svg')
          }, null, 8, _hoisted_2), [
            [_vShow, _ctx.value]
          ])
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("p", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}