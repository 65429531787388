
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'OrderDraftFooter',
  computed: {
    ...mapGetters('payment', [
      'draftExtra',
      'draft'
    ]),
    ...mapGetters('loyalty', [
      'clubConfig',
      'currencySymbol'
    ]),
    ...mapGetters('order', [
      'orderTotal',
      'orderEditable'
    ]),
    text () {
      if (this.clubConfig.onlyPayment) {
        return 'К оплате'
      } else {
        return 'К доплате'
      }
    },
    sum (): string {
      if (this.orderEditable) {
        return this.orderTotal
      }
      return this.draftExtra.spend
    }
  }
})
