import { infoApi } from '@/api/info'
import { authApi } from '@/api/auth'

const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async getInfo ({ commit, dispatch, rootGetters }: any): Promise<any> {
      try {
        commit('SET_LOADING_STATUS', 'pending', { root: true })

        const isExist = await dispatch('auth/checkUserToken', null, { root: true })
        if (!isExist) {
          commit('SET_LOADING_STATUS', 'error', { root: true })
        }
        const res = await infoApi.getInfo()
        const { user, balance, loyalty, order, draft } = res.data.data

        // eslint-disable-next-line no-inner-declarations
        function clearLogin (login: string): string {
          return login.split('_')[0].split('WEB')[0]
        }

        // Сценарий молчаливой авторизации
        if (order.userPhoneNumber) {
          const currentUser = clearLogin(rootGetters['auth/login'])
          const orderPhone = order.userPhoneNumber

          // Пользователь из localStorage !== номер телефона в заказе
          if (currentUser !== orderPhone) {
            // Пытаемся совершить "молчаливую авторизацию"
            const res = await authApi.register()
            if (clearLogin(res.data.data.login) !== orderPhone) {
              // Полученный логин не равен номеру телефона в заказе,
              // значит пользователь не зарегистрирован в системе
              // предлагаем зарегистрироваться
              commit('modal/TOGGLE_MODAL', {
                value: true,
                name: 'auth',
                permanent: false
              }, { root: true })
            } else {
              // Молчаливая авторизация прошла успешно, записываем новые данные пользователя
              commit('auth/REGISTER', res.data.data, { root: true })
            }
          }
        }

        if (!user.anonymous) {
          const token = localStorage.getItem('bmsCheckoutToken')
          const refresh = localStorage.getItem('bmsCheckoutRefresh')
          const loginData = {
            token,
            refresh,
            ...user
          }
          commit('auth/LOGIN', loginData, { root: true })
        }
        if (!rootGetters['user/email']) {
          commit('user/SET_EMAIL', user.email, { root: true })
        }

        commit('user/SET_BALANCE', balance, { root: true })
        if (balance) {
          commit('auth/SECRET_AUTHORIZE', null, { root: true })
        }
        // commit('user/SET_BINDING_CARDS', balance, { root: true })

        commit('loyalty/SET_RETAIL_POINT', loyalty.retailPoint, { root: true })

        commit('loyalty/SET_CLUB', loyalty.club, { root: true })

        commit('order/SET_ORDER', order, { root: true })

        commit('paymentTypes/SET_PAYMENT_TYPE', loyalty.club.config.paymentTypes[0], { root: true })

        commit('payment/CREATE_DRAFT', draft, { root: true })

        await dispatch('payment/checkPaymentAvailable', null, { root: true })
        commit('SET_LOADING_STATUS', 'ok', { root: true })
      } catch (e: any) {
        console.log(e)
        commit('SET_LOADING_STATUS', 'error', { root: true })

        if (e.data && e.data.error && e.data.error.code === 2102) {
          await dispatch('error/toggleError', {
            name: 'not-found',
            value: true
          }, { root: true })
        } else if (e && e.data && e.data.error && e.data.error.code === 2109) {
          dispatch('modal/toggleModal', { name: 'auth', value: true, permanent: true }, { root: true })
        } else {
          await dispatch('error/toggleError', {
            name: 'internal',
            value: true
          }, { root: true })
        }
      }
    }
  },
  getters: {}
}
