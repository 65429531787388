import Modal from '@/models/modal.model'

const getDefaultState = () => {
  return {
    value: false,
    name: '',
    permanent: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    TOGGLE_MODAL (state: Modal, data: Modal): void {
      state.value = data.value
      state.name = data.name
      state.permanent = data.permanent || false
    },
    RESET_STATE (state: any) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    toggleModal ({ commit }: any, data: Modal): void {
      commit('TOGGLE_MODAL', data)
    }
  },
  getters: {
    value: (state: Modal): boolean => state.value,
    name: (state: Modal): string => state.name,
    permanent: (state: Modal): boolean => state.permanent
  }
}
