import { server } from './index'
import { AxiosResponse } from 'axios'

const userApi = {
  /**
   * Get user balance
   * @returns {Promise<AxiosResponse<T>>}
   */
  getBalance (): Promise<AxiosResponse> {
    return server.get('user/balance')
  }
}

export { userApi }
