
import { defineComponent } from 'vue'
import Switch from '@/components/UI/inputs/Switch.vue'
import Button from '@/components/UI/buttons/Button.vue'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
  name: 'Loyalty',
  components: {
    'app-switch': Switch,
    'app-button': Button
  },
  props: {
    isAuthorized: {
      type: Boolean,
      required: true
    },
    isSecretAuthorized: {
      type: Boolean,
      required: true
    },
    isCreated: {
      type: Boolean,
      required: true
    },
    bonusAmount: {
      type: Number,
      required: true
    },
    bonusOnly: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['isExternalPage']),
    ...mapGetters('loyalty', [
      'clubTitle'
    ]),
    ...mapGetters('payment', [
      'isSpendBonus'
    ]),
    ...mapGetters('user', [
      'cardNum'
    ]),
    ...mapGetters('paymentTypes', [
      'paymentType'
    ]),
    spend: {
      get () {
        return this.isSpendBonus
      },
      set (value: boolean) {
        this.setSpendBonus(value)
      }
    }
  },
  data: () => ({}),
  watch: {
    async spend () {
      await this.createDraft()
    }
  },
  methods: {
    ...mapActions('payment', [
      'setSpendBonus',
      'createDraft'
    ])
  }
})
