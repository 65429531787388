import Order from '@/models/order.model'
const getDefaultState = () => {
  return {
    id: '',
    bonusOnly: false,
    goods: [],
    total: 0,
    totalValid: true,
    editable: false,
    completed: false,
    userPhoneNumber: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_ORDER (state: Order, data: any) {
      state.id = data.id
      state.bonusOnly = data.bonusOnly
      state.goods = data.goods
      state.total = data.total
      state.editable = data.editable
      state.completed = data?.completed || false
      state.userPhoneNumber = data.userPhoneNumber
    },
    SET_TOTAL (state: Order, value: string) {
      state.total = +value
      state.goods[0].price = +value
      state.goods[0].sum = +value
    },
    SET_TOTAL_VALID (state: Order, value: boolean) {
      state.totalValid = value
    },
    RESET_STATE (state: Order) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
  },
  getters: {
    orderId: (state: Order) => state.id,
    bonusOnly: (state: Order) => state.bonusOnly,
    orderGoods: (state: Order) => state.goods,
    orderTotal: (state: Order) => state.total,
    orderTotalValid: (state: Order) => state.totalValid,
    orderEditable: (state: Order) => state.editable,
    orderCompleted: (state: Order) => state.completed
  }
}
