
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Logo from '@/components/header/components/Logo.vue'
import Login from '@/components/header/components/Login.vue'
import Logout from '@/components/header/components/Logout.vue'

export default defineComponent({
  name: 'Header',
  components: {
    'app-logo': Logo,
    'app-login': Login
  },
  props: {
    loyalty: {
      type: Boolean,
      required: true
    },
    isAuthorized: {
      type: Boolean,
      required: true
    },
    isCreated: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    clubConfig: {
      handler (val) {
        if (val?.needAuth) {
          this.login()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('loyalty', [
      'clubConfig'
    ])
  },
  methods: {
    ...mapActions('modal', [
      'toggleModal'
    ]),
    ...mapActions('auth', [
      'logout'
    ]),
    async login () {
      if (this.isAuthorized) {
        await this.logout()
      } else {
        const permanent = this.clubConfig.needAuth

        this.toggleModal({
          name: 'auth',
          value: true,
          permanent
        })
      }
    }
  }
})
