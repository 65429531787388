
import { createPopper } from '@popperjs/core'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tooltip',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Array,
      required: false,
      default () {
        return [22, 22]
      }
    },
    message: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      popper: null as any,
      visible: false
    }
  },
  beforeUnmount () {
    this.destroy()
  },
  methods: {
    show () {
      this.visible = true
      this.create()
    },
    hide () {
      this.visible = false
    },
    create () {
      this.popper = createPopper(this.$refs.button as HTMLElement, this.$refs.tooltip as HTMLElement, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 9]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 15
            }
          }
        ]
      })
    },
    destroy () {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    }
  }
})
