import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home-order" }
const _hoisted_3 = { class: "card home-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_order = _resolveComponent("app-order")!
  const _component_app_loyalty = _resolveComponent("app-loyalty")!
  const _component_app_payment = _resolveComponent("app-payment")!
  const _component_app_order_draft = _resolveComponent("app-order-draft")!
  const _component_app_order_form = _resolveComponent("app-order-form")!
  const _component_app_preloader = _resolveComponent("app-preloader")!

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_order)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loyalty)
        ? (_openBlock(), _createBlock(_component_app_loyalty, {
            key: 0,
            "is-authorized": _ctx.isAuthorized,
            "is-secret-authorized": _ctx.isSecretAuthorized,
            "is-created": _ctx.isCreated,
            "bonus-amount": _ctx.bonusAmount,
            "bonus-only": _ctx.bonusOnly
          }, null, 8, ["is-authorized", "is-secret-authorized", "is-created", "bonus-amount", "bonus-only"]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_payment, { "bonus-only": _ctx.bonusOnly }, null, 8, ["bonus-only"]),
      _createVNode(_component_app_order_draft, { "bonus-only": _ctx.bonusOnly }, null, 8, ["bonus-only"]),
      _createVNode(_component_app_order_form, {
        "bonus-only": _ctx.bonusOnly,
        "is-payment-available": _ctx.isPaymentAvailable,
        onPay: _ctx.pay
      }, null, 8, ["bonus-only", "is-payment-available", "onPay"])
    ]),
    _createVNode(_component_app_preloader, {
      value: _ctx.loadingStatus === 'pending'
    }, null, 8, ["value"])
  ]))
}