
import { defineComponent } from 'vue'
import OrderDraftMain from '@/components/home/payment/components/draft/components/Main.vue'
import OrderDraftFooter from '@/components/home/payment/components/draft/components/Footer.vue'

export default defineComponent({
  name: 'OrderDraft',
  components: {
    'app-order-draft-main': OrderDraftMain,
    'app-order-draft-footer': OrderDraftFooter
  },
  props: {
    bonusOnly: {
      type: Boolean,
      required: true
    }
  }
})
